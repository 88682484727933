import React, { useEffect, useState } from 'react';
import {
  Box, Button, Modal, TextField, Typography, MenuItem,
  FormControl, InputLabel, Select, ListItemText, Checkbox, OutlinedInput,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide
} from '@mui/material';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';

const dayjs = require('dayjs');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflowY: 'auto',
  zIndex: 1300, // Ensure the modal is below the dialog
};

const dialogStyle = {
  zIndex: 1400, // Ensure the dialog is above the modal
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddSponsorModal = ({ open, handleClose, fetchSponsors, setAlertOpen, setMessage, setSeverity,sponsors }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState(null);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [rankNum, setRankNum] = useState(''); // New state for rankNum
  const [rankInput, setRankInput] = useState(''); // New state for rank input

  const [progress, setProgress] = useState(false);

  const handlekOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  useEffect(() => {
    if (open) fetchAvailableEvents();
  }, [open]);

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setAvailableEvents(filteredEvents);
    } catch (error) {
      setMessage("Error fetching available events");
      setSeverity("error");
      setAlertOpen(true);
    }
  };
  useEffect(() => {
    fetchSponsors();
  }, []);

  

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];

    const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSizeBytes) {
      setError('File size exceeds the limit of 2MB. Please choose a smaller file.');
      setPhoto(null);
      return;
    }
    if (file) {
      const extension = file.name.split('.').pop();
      const newName = `edugate_sponsor_${Date.now()}.${extension}`;
      const renamedFile = new File([file], newName, { type: file.type });
      setPhoto(renamedFile);
    }
  };

  const handleEventChange = (event) => {
    const { target: { value } } = event;
    setSelectedEvents(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);
    try {
      if (selectedEvents.length === 0) {
        handlekOpenDialog();
        return;
      }
      if (!photo) {
        setError('Image cannot be left empty. upload a picture please!');
      return;
  
      }


      const formData = new FormData();
      const capitalizedEventName = capitalizeFirstLetter(name);

      formData.append('name', capitalizedEventName);
      formData.append('type', type);
      formData.append('description', description);
      formData.append('image', photo);
      formData.append('event_ids', selectedEvents.join(','));
      formData.append('create_date', dayjs(new Date()).format('YYYY-MM-DD hh:mm:ssa') );
      formData.append('rankNum', rankNum); // Append rankNum


      const response = await axios.post('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/addsponsor', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
        fetchSponsors();
        handleCloseModal();
        setMessage("Sponsor created successfully");
        setSeverity("success");
        setAlertOpen(true);
      } else {
        handleCloseModal();
        setMessage("Error adding sponsor");
        setSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      handleCloseModal();
      setMessage("Error adding sponsor");
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);

  };
  const handleRankChange = (e) => {
    const value = Number(e.target.value);
    const maxRank = Math.max(...sponsors.map(s => s.rankNum), 0);
    const computedRankNum = maxRank - value + 1;
    setRankNum(computedRankNum);
    setRankInput(value);
  };
  

  const handleCloseModal = () => {
    setName('');
    setType('');
    setDescription('');
    setPhoto(null);
    setSelectedEvents([]);
    setError("");
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={dialogStyle}
      >
        <DialogTitle>{"Adding new Sponsor"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please select at least one event to add a new sponsor
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Modal
  open={open}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
  sx={{ zIndex: 1300 }} // Ensure the modal is below the dialog
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: 600,
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      overflowY: 'auto',
      maxHeight: '85%',
    }}
  >
    <Typography
      id="modal-title"
      variant="h4"
      component="h2"
      sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
    >
      Add New Sponsor
    </Typography>

    <form encType="multipart/form-data" onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal">
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal" required>
        <InputLabel id="type-label">Type</InputLabel>
        <Select
          labelId="type-label"
          label="Type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          fullWidth
          required
          variant="outlined"
        >
          <MenuItem value="Silver">Silver</MenuItem>
          <MenuItem value="Gold">Gold</MenuItem>
          <MenuItem value="Platinum">Platinum</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          multiline
          rows={4}
          variant="outlined"
        />
      </FormControl>

      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="upload-photo"
        type="file"
        onChange={handlePhotoUpload}
      />
      <label htmlFor="upload-photo">
        <Button
          startIcon={<CloudUploadIcon />}
          variant="outlined"
          component="span"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            fontWeight: 'bold',
            borderColor: 'primary.main',
            color: 'primary.main',
          }}
        >
          Upload Photo
        </Button>
      </label>
      {photo && (
        <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>
      )}

      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <FormControl fullWidth margin="normal" sx={{ mt: 4 }}>
        <InputLabel id="events-label">Choose Events</InputLabel>
        <Select
          labelId="events-label"
          multiple
          value={selectedEvents}
          onChange={handleEventChange}
          input={<OutlinedInput label="Choose Events" />}
          renderValue={(selected) => selected.map(id => {
            const event = availableEvents.find(event => event.id === id);
            return event ? event.name : id;
          }).join(', ')}
          MenuProps={MenuProps}
          variant="outlined"
        >
          {availableEvents.map((event) => (
            <MenuItem key={event.id} value={event.id}>
              <Checkbox checked={selectedEvents.indexOf(event.id) > -1} />
              <ListItemText primary={event.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Rank Number"
          type="number"
          value={rankInput}
          onChange={handleRankChange}
          fullWidth
          required
          inputProps={{ min: 1, max: (sponsors.length + 1) }}
          variant="outlined"
        />
      </FormControl>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
      >
        Add Sponsor
      </Button>

      {progress && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </form>
  </Box>
</Modal>

    </div>
  );
};

export default AddSponsorModal;
