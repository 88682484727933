import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Grid, Box } from '@mui/material';

const FilterComponent = ({ filters, handleFilterChange, getUniqueValues, availableEvents }) => {
  return (
    <Box sx={{ p: 2, backgroundColor: 'white', borderRadius: '8px', boxShadow: 2 }}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {[
          { label: 'Status', name: 'status', values: getUniqueValues('status') },
          { label: 'Administration', name: 'administration', values: getUniqueValues('administration') },
          { label: 'Type', name: 'type', values: getUniqueValues('type') },
          { label: 'City', name: 'city', values: getUniqueValues('city') },
          { label: 'Event', name: 'event', values: availableEvents.map(event => ({ value: event.id, label: event.name })) }
        ].map((filter, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>{filter.label}</InputLabel>
              <Select
                name={filter.name}
                value={filters[filter.name]}
                onChange={handleFilterChange}
                label={filter.label}
                sx={{
                  '& .MuiSelect-outlined': {
                    borderRadius: '8px',
                  },
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {filter.values.map((item, idx) => (
                  <MenuItem key={idx} value={item.value || item}>
                    {item.label || item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default FilterComponent;
