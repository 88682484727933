import React, { useEffect,useState } from 'react';
import {
  
  Button, IconButton, Box,Grid,
  Collapse
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash'; // Import lodash
import LinearProgress from '@mui/material/LinearProgress';
import AddModal from './SchoolComp/AddModal';
import SchoolTable from './SchoolComp/SchoolTable';
import ContactModal from './SchoolComp/ContactModal';
import EditModal from './SchoolComp/EditModal';
import FilterComponent from './SchoolComp/FilterComponent';
import SearchComponent from './SchoolComp/SearchComponent';
import TrophyModal from './SchoolComp/TrophyModal';

const SchoolManagement = () => {
    const [schools, setSchools] = useState([]);
    const [filters, setFilters] = useState({
    status: '',
    administration: '',
    type: '',
    city: '',
  });
  const [trophyModalOpen, setTrophyModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [newContact, setNewContact] = useState({ name: '', phone: '' });
  const [governoratesInEgypt, setGovernoratesInEgypt] = useState([]);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialSchool, setInitialSchool] = useState(null); // New state to hold initial event data
  const [progress, setProgress] = useState(false);


  const [updatedContact, setUpdatedContact] = useState(null);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [newSchool, setNewSchool] = useState({
    name: '',
    representative: '',
    recommendedBy: '',
    email: '',
    phone: '',
    type: '',
    administration: '',
    city: '',
    status: 'Never Came',
    comment: '',
    contacts: [],
    address:"",
    which_day:"",
    events: [],
    trophy:[]
  });
  const [newTrophy, setNewTrophy] = useState({
    title: '',
    name: '',
    phone: '',
    year: '',
  });
  const handleNewTrophyChange = (event) => {
    const { name, value } = event.target;
    setNewTrophy((prevTrophy) => ({
      ...prevTrophy,
      [name]: value,
    }));
  };
  const [phoneNumbernew, setphoneNumbernew] = useState(false); // New state to track checkbox changes

  
  


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures');
        setGovernoratesInEgypt(response.data[2].governoratesInEgypt);
        fetchSchools();
        fetchAvailableEvents();
      } catch (error) {
        console.error('Error fetching figures data:', error);
        setMessage("Error fetching figures data");
        setSeverity("error");
        setAlertOpen(true);
      }

    };
  
    fetchData();
  }, []);
  

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter((event) => event.deleted === '0');
      setAvailableEvents(filteredEvents);
    } catch (error) {
      setMessage("Error fetching events");
      setSeverity("error");
      setAlertOpen(true);
    }
  };
  
  const fetchSchools = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getschools');
      setSchools(response.data.reverse());
    } catch (error) {
      console.error('Error fetching schools:', error);
      setMessage("Error fetching schools");
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);

  };
  
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleContactOpen = (school) => {
    setSelectedSchool(school);
    setContactModalOpen(true);
  };

  const handleContactClose = () => {
    setContactModalOpen(false);
    setSelectedSchool(null);
  };
  const handleTrophyOpen = (school) => {
    setSelectedSchool(school);
    setTrophyModalOpen(true);
  };

  const handleTrophyClose = () => {
    setTrophyModalOpen(false);
    setSelectedSchool(null);
  };

  const handleEditOpen = (school) => {
    setSelectedSchool(school);
    setInitialSchool(school);
    setphoneNumbernew(false); // Reset checkbox changed state
    setEditModalOpen(true);
  };

  const handleEditClose = () => {
    setEditModalOpen(false);
    setSelectedSchool(null);
    setInitialSchool(null);

  };

  const handleEditSave = () => {
    if (!selectedSchool) {
      console.error('selectedSchool is null or undefined');
      return; // Exit the function if selectedSchool is not defined
  }
    const { _id, ...schoolData } = selectedSchool;
 // Handle new contacts
    console.log(schoolData)
    const isPhoneExists = selectedSchool.contacts.some(contact => contact.phone === newContact.phone);

  if (isPhoneExists) {
    setMessage("This phone number already exists in the contacts.");
    setSeverity("error");
    setEditModalOpen(false);
    setSelectedSchool(null);
    setUpdatedContact(null);
    setNewContact({ name: '', phone: '' });
    setAlertOpen(true);
    return; // Stop the save process
  }
    const newContacts = [];
    if (newContact.name.trim() && newContact.phone.trim()) {
        newContacts.push({ name: newContact.name, phone: newContact.phone });
    }
  
    // Handle new trophies
    const newTrophies = [];
    if (
        newTrophy.title.trim() &&
        newTrophy.name.trim() &&
        newTrophy.phone.trim() &&
        newTrophy.year.trim()
    ) {
        newTrophies.push({
            title: newTrophy.title,
            name: newTrophy.name,
            phone: newTrophy.phone,
            year: newTrophy.year,
        });
    }
  
    // Ensure events are formatted correctly
    const updatedEvents = selectedSchool.events.map(event => ({
        event_id: event.event_id || '', // Ensure event_id is not undefined
        which_day: event.which_day || '' // Default to empty string if not provided
    }));
  
    const updatedSchoolData = {
        ...schoolData,
        contacts: [...schoolData.contacts, ...newContacts],
        trophy: [...newTrophies, ...schoolData.trophy],
        events: updatedEvents
    };
  
    const hasChanged = !_.isEqual(initialSchool, selectedSchool) || phoneNumbernew;
  
    if (hasChanged) {
        setProgress(true);
  
        axios
            .put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/updateschools/${selectedSchool._id}`, updatedSchoolData)
            .then(() => {
                fetchSchools();
                setEditModalOpen(false);
                setSelectedSchool(null);
                setNewContact({ name: '', phone: '' });
                setNewTrophy({ title: '', name: '', phone: '', year: '' });
                setMessage("School updated successfully");
                setSeverity("success");
                setAlertOpen(true);
                setUpdatedContact(null);
            })
            .catch((error) => {
                setMessage("Error updating school");
                setSeverity("error");
                setAlertOpen(true);
            })
            .finally(() => {
                setProgress(false); // Ensure progress is reset regardless of request outcome
            });
    } else {
        handleEditClose();
    }
};

useEffect(() => {
  if (updatedContact) {
    handleEditSave(); // Ensure that this is called with the latest selectedSchool state
  }
}, [selectedSchool]); // Dependency on selectedSchool ensures this effect runs after it's updated

  const handleContactChange = (event) => {
    const { name, value } = event.target;
    console.log("Change event triggered");
    setNewContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
    setphoneNumbernew(true);
  };
  

  const handleSchoolChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'events') {
      // Update the entire events array
      setSelectedSchool((prevSchool) => ({
        ...prevSchool,
        events: value
      }));
    } else if (name.startsWith('events.')) {
      const [_, eventIndexStr, field] = name.split('.');
      
      const eventIndex = parseInt(eventIndexStr, 10);
  
      if (isNaN(eventIndex) || !field) {
        console.error("Invalid event index or field name in input name:", name);
        return;
      }
      
      setSelectedSchool((prevSchool) => ({
        ...prevSchool,
        events: prevSchool.events.map((eventItem, index) =>
          index === eventIndex
            ? { ...eventItem, [field]: value }
            : eventItem
        )
      }));
    } else {
      // Update simple fields
      setSelectedSchool((prevSchool) => ({
        ...prevSchool,
        [name]: value,
      }));
    }
  };
  

  const handleAddOpen = () => {
    setAddModalOpen(true);
  };

  const handleAddClose = () => {
    setAddModalOpen(false);
    setNewSchool({
      name: '',
      representative: '',
      recommendedBy: '',
      email: '',
      type: '',
      administration: '',
      city: '',
      status: 'Never Came',
      comment: '',
      contacts: [],
      address:"",
      which_day:"",
      events: [],
      trophy:[]
    });
  };
  const openWhatsAppChat = (phone) => {
    return `https://wa.me/+2${phone}`;
  };
  
  const handleAddSave = () => {
    const updatedNewSchool = {
      ...newSchool,
      contacts: [{ name: newSchool.representative, phone: newSchool.phone }, ...newSchool.contacts],
      events: newSchool.events.map(event => ({
        event_id: event.id,
        which_day: event.which_day || '' // Default to empty string if not provided
      }))
    };
  
    setProgress(true);
  
    axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/addschools', updatedNewSchool)
      .then(() => {
        fetchSchools();
        setMessage("School added successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleAddClose();
      })
      .catch(error => {
        console.error('Error adding school:', error);
        setMessage("Error adding school");
        setSeverity("error");
        setAlertOpen(true);
      });
    setProgress(false);
  };
  

  const getUniqueValues = (key) => {
    return [...new Set(schools.map((school) => school[key]))];
  };
  const handleNewSchoolChange = (event) => {
    const { name, value } = event.target;
    setNewSchool((prevSchool) => ({
      ...prevSchool,
      [name]: value,
    }));
  };
  
  const handleCityInputChange = (event, value, reason) => {
    // Handle different reasons for input change
    if (reason === 'input') {
      // Handle user typing into the input field
      setNewSchool((prevSchool) => ({
        ...prevSchool,
        city: value || '', // Ensure city is a string
      }));
    } else if (reason === 'clear') {
      // Handle clearing the input field
      setNewSchool((prevSchool) => ({
        ...prevSchool,
        city: '', // Reset city to an empty string
      }));
    } else if (reason === 'select-option') {
      // Handle when a city option is selected
      const selectedCity = value ? value.label : ''; // Safeguard against null or undefined values
      if (addModalOpen) {
        setNewSchool((prevSchool) => ({
          ...prevSchool,
          city: selectedCity,
        }));
      } else {
        setSelectedSchool((prevSchool) => ({
          ...prevSchool,
          city: selectedCity,
        }));
      }
    }
  };
  
  
  const handleSearchChange = (newInputValue) => {
    setSearchQuery(newInputValue);
  };
  
  const handleContactEdit = (updatedContact) => {
    console.log(updatedContact)
    console.log(selectedSchool)

    const updatedSchool = {
      ...selectedSchool,
      contacts: selectedSchool.contacts.map(contact =>
        contact.phone === updatedContact.oldPhone // Compare old phone number to identify the contact
          ? { ...contact, name: updatedContact.name, phone: updatedContact.phone }
          : contact
      )
    };
    console.log(updatedSchool)
  
    setSelectedSchool(updatedSchool);
    setUpdatedContact(updatedContact); // Set updated contact to trigger useEffect

   // handleEditSave(); // Call the function to save changes
  };
  const handleTrophyEdit = (updatedTrophy) => {
    const { index, ...trophyData } = updatedTrophy; // Extract index and updated data

    const updatedSchool = {
      ...selectedSchool,
      trophy: selectedSchool.trophy.map((trophy, i) =>
        i === index ? trophyData : trophy
      ),
    };

    setSelectedSchool(updatedSchool); // Update the selectedSchool state
    setUpdatedContact(updatedTrophy); // Trigger save if necessary
  };

  


  const filteredSchools = schools.filter((school) => {
    const matchesStatus = filters.status ? school.status === filters.status : true;
    const matchesAdministration = filters.administration ? school.administration === filters.administration : true;
    const matchesType = filters.type ? school.type === filters.type : true;
    const matchesCity = filters.city ? school.city === filters.city : true;
  
    const matchesSearchQuery =
  searchQuery.trim() === '' ||
  school.name.toLowerCase().includes(searchQuery.trim().toLowerCase()) ||
  school.contacts.some(contact => contact.phone.includes(searchQuery.trim()));

  
      const matchesAvailableEvents =
      !filters.event || // If no event is selected, show all schools
      school.events.some(event => event.event_id === filters.event); // Check if school has the selected event
    
    return (
      matchesStatus &&
      matchesAdministration &&
      matchesType &&
      matchesCity &&
      matchesSearchQuery &&
      matchesAvailableEvents
    );
  });
  
  
  

  
  
  return (
    <div>
      <Box sx={{ width: '100%' }}>
      <Collapse in={alertOpen}>
        <Alert
        severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
    <Grid item xs={12}>
<FilterComponent
      filters={filters}
      handleFilterChange={handleFilterChange}
      getUniqueValues={getUniqueValues}
      availableEvents={availableEvents} 
    />        </Grid>
     
    <Grid item xs={12} sm={6} md={4} lg={3}>
    <SearchComponent
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
      />
      </Grid>

      <Grid  item xs={12} sm={6} md={4} lg={3}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddOpen}
          fullWidth
          style={{margin:"10px"}}
        >
          Add School
        </Button>
      </Grid>
      {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}

<Grid item xs={12}>
        <SchoolTable
          filteredSchools={filteredSchools}
          handleEditOpen={handleEditOpen}
          handleContactOpen={handleContactOpen}
          handleTrophyOpen={handleTrophyOpen}
          availableEvents={availableEvents} // Pass the availableEvents prop
        />
      </Grid>

      <ContactModal
        contactModalOpen={contactModalOpen}
        handleContactClose={handleContactClose}
        selectedSchool={selectedSchool}
        handleContactEdit={handleContactEdit} // Pass the handler
        handleEditSave={handleEditSave}

      />
    <TrophyModal
  trophyModalOpen={trophyModalOpen}
  handleTrophyClose={handleTrophyClose}
  selectedSchool={selectedSchool}
  newTrophy={newTrophy} // Ensure this is defined in the parent component
  handleNewTrophyChange={handleNewTrophyChange} // Ensure this is defined in the parent component
  handleEditSave={handleEditSave} // Ensure this is defined in the parent component
  progress={progress} // Ensure this is defined in the parent component
  handleTrophyEdit={handleTrophyEdit}
/>

<EditModal
        editModalOpen={editModalOpen}
        handleEditClose={handleEditClose}
        selectedSchool={selectedSchool}
        handleSchoolChange={handleSchoolChange}
        handleContactChange={handleContactChange}
        handleEditSave={handleEditSave}
        newContact={newContact}
        newTrophy={newTrophy}
        handleNewTrophyChange={handleNewTrophyChange}
        progress={progress}
        governoratesInEgypt={governoratesInEgypt}
        availableEvents={availableEvents}
        handleCityInputChange={handleCityInputChange}
      />
<AddModal
    addModalOpen={addModalOpen}
    handleAddClose={handleAddClose}
    handleAddSave={handleAddSave}
    newSchool={newSchool}
    handleNewSchoolChange={handleNewSchoolChange}
    governoratesInEgypt={governoratesInEgypt}
    handleCityInputChange={handleCityInputChange}
    progress={progress}
    availableEvents={availableEvents}
  />

    </div>
  );
};

export default SchoolManagement;
