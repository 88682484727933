import React, { useEffect,useState } from 'react';
import { Box, IconButton,Button, Tooltip,FormControl,TextField, Typography, Container, CssBaseline } from '@mui/material';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./RegistrationForm.css"
import axios from 'axios';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import ReportProblem from './ReportProblem';

import InputLabel from '@mui/material/InputLabel';
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    }
  }
});

const StyledForm = styled('form')({
  position:"relative",
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '90vw', // Set the width as 90% of the viewport width
  margin: '0 auto',
  background: 'rgba(255, 255, 255, 0.9)', // Semi-transparent background
  padding: '20px', // Add padding for better readability
  borderRadius: '10px', // Rounded corners
});

const BackgroundContainer = styled(Container)({
  background: 'url("https://edugate-eg.com/wp-content/uploads/2024/05/8O0A1877_result-copy.png")', // Replace with your background image URL
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})



const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  0:{
    label:""

  },
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" style={{width:"40px",height:"40px"}} />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" style={{width:"40px",height:"40px"}} />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" style={{width:"40px",height:"40px"}} />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" style={{width:"40px",height:"40px"}} />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" style={{width:"40px",height:"40px"}} />,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const FeedbackForm = () => {
  const [overallExperience, setOverallExperience] = useState(0);
  const [venueAndFacilities, setVenueAndFacilities] = useState(0);
  const [organization, setOrganization] = useState(0);
  const [timingAndScheduling, setTimingAndScheduling] = useState(0);
  const [qualityOfInteractions, setQualityOfInteractions] = useState(0);
  const [networkingOpportunities, setNetworkingOpportunities] = useState(0);
  const [promotionAndOutreach, setPromotionAndOutreach] = useState(0);
  const [informationAccessibility, setInformationAccessibility] = useState(0);
  const [collaborativeOpportunities, setCollaborativeOpportunities] = useState(0);
  const [additionalComments, setAdditionalComments] = useState('');
  const [availableEvents, setAvailableEvents] = useState([]); // State variable for available events
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setprogress] = useState(false);


  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setAvailableEvents(filteredEvents);
    } catch (error) {
      console.error('Error fetching available events:', error);
    }
  };
  
  useEffect(() => {
    fetchAvailableEvents();
  }, []);


  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const [formData, setFormData] = useState({
    uni_name: '',
    email: '', // 'success' or 'error'
    name: '',
    whichEvent:""
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

      setFormData({
        ...formData,
        [name]: value, // Update other fields with their values
      });
    
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    if (
      overallExperience === 0 ||
      venueAndFacilities === 0 ||
      organization === 0 ||
      timingAndScheduling === 0 ||
      qualityOfInteractions === 0 ||
      networkingOpportunities === 0 ||
      promotionAndOutreach === 0 ||
      informationAccessibility === 0 ||
      collaborativeOpportunities === 0
    ) {
      setFeedback({
        message: 'You need to fill all the Questions',
        type: 'error',
      });
    } else {
      const data = {
        overallExperience: overallExperience,
        venueAndFacilities: venueAndFacilities,
        organization: organization,
        timingAndScheduling: timingAndScheduling,
        qualityOfInteractions: qualityOfInteractions,
        networkingOpportunities: networkingOpportunities,
        promotionAndOutreach: promotionAndOutreach,
        informationAccessibility: informationAccessibility,
        collaborativeOpportunities: collaborativeOpportunities,
        email: formData.email,
        name: formData.name,
        university: formData.uni_name,
        additionalComments: additionalComments,
        whichEvent:formData.whichEvent
      };
      setprogress(true);

      try {
        setIsSubmitting(true);

        const response = await fetch('https://edutech-backend-471f7cb69116.herokuapp.com/api/uni-feedback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        if (response.status === 201) {
          setFeedback({
            message: 'Your Response was submitted Successfully. Thank you for your time. ',
            type: 'success',
          });
        } else {
          // Handle errors from the backend
          setFeedback({
            message: 'Error submitting the form',
            type: 'error',
          });
        }
      } catch (error) {
        // Handle network errors
        setFeedback({
          message: error,
          type: 'error',
        });
      }
    }
    setprogress(false);

  };
  




  return (
    <div>

   <BackgroundContainer component="main" maxWidth="">

<div style={{display: 'flex', alignItems: 'center',marginBottom:"50px",marginTop:"50px", width:"80vw"}} className="form-menu-container">

            <ThemeProvider theme={theme}>
            <CssBaseline />

        <StyledForm onSubmit={handleSubmit}>

        <ThemeProvider theme={theme}>
        <Box sx={{ position: 'absolute', right: 16, top: 16 }}> {/* Positioning the ReportProblem component */}
    <Tooltip title="Report a Problem">
    <IconButton
  color="inherit"
  aria-label="notifications"
  sx={{
    mr: 2,
    zIndex: 1200, // Ensures the menu appears on top of other elements
  }}
>
          <ReportProblem />
          </IconButton>
        </Tooltip>
        </Box>

          <Box sx={{ textAlign: 'center', mt: 4 }}>

<StyledTitle variant="h5" gutterBottom>
EDU GATE Event Feedback Form
</StyledTitle>
</Box>
          <Typography style={{color:"grey"}} variant="h6" >
            Thank you for participating in our university event! Your feedback is crucial for us to improve future events and better serve universities like yours. Please take a moment to share your thoughts with us.
          </Typography>
          </ThemeProvider>
          <Grid mb={2} item xs={12} sm={6}>
          <FormControl required fullWidth>
            <InputLabel id="event-select-label" >Select Event</InputLabel>
            <Select
            label="Select Event"
            name="whichEvent"
             labelId="event-select-label"
                  required
                  value={formData.whichEvent} 
                  onChange={handleChange}
                  fullWidth
                >
                  {availableEvents.map((event) => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              </Grid>
          <TextField
            label="Name of University"
            fullWidth
            margin="normal"
            variant="outlined"
            required
            name="uni_name"
            value={formData.uni_name}
            onChange={handleChange}
          />
          <TextField
            label="Contact Person"
            fullWidth
            margin="normal"
            variant="outlined"
            required
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            variant="outlined"
            required
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              How would you rate your university's overall experience at the event?
            </Typography>

            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center',fontSize: '6rem' }}>

              <StyledRating
              required
              size="large"
              fontSize="6rem"
              sx={{ fontSize: '6rem' }} // Adjust the font size here
                name="overall-experience"
                value={overallExperience}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly                   
                onChange={(event, newValue) => {
                  setOverallExperience(newValue);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55,width:"32px",height:"32px" }} fontSize="inherit" />}
              />
            <Typography ml={2}>
              {overallExperience > 0 ? customIcons[overallExperience].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Add similar rating sections for other aspects */}
          {/* Venue and Facilities */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              How would you rate the venue and facilities for representing your university effectively?
            </Typography>
            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center' }}>
              <StyledRating
              required
              size="large"
                name="venue-and-facilities"
                value={venueAndFacilities}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly                
                onChange={(event, newValue) => {
                  setVenueAndFacilities(newValue);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55,width:"32px",height:"32px" }} fontSize="inherit" />}
              />

              <Typography ml={2}>
              {venueAndFacilities > 0 ? customIcons[venueAndFacilities].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Organization */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              How well-organized was the event from the perspective of university representatives?
            </Typography>
            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center' }}>
              <StyledRating
              required
                size="large"
                name="organization"
                value={organization}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly                onChange={(event, newValue) => {
                  setOrganization(newValue);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55,width:"32px",height:"32px" }} fontSize="inherit" />}
              />
              <Typography ml={2}>
              {organization > 0 ? customIcons[organization].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Timing and Scheduling */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Was the timing and scheduling of the event convenient for your university's recruitment needs?
            </Typography>
            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center' }}>
              <StyledRating
              required
              size="large"
                name="timing-and-scheduling"
                value={timingAndScheduling}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly                onChange={(event, newValue) => {
                  setTimingAndScheduling(newValue);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 ,width:"32px",height:"32px"}} fontSize="inherit" />}
              />
              <Typography ml={2}>
              {timingAndScheduling > 0 ? customIcons[timingAndScheduling].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Quality of Interactions */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Did you find the interactions with attending students and other university representatives valuable?
            </Typography>
            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center' }}>
              <StyledRating
              required
              size="large"
                name="quality-of-interactions"
                value={qualityOfInteractions}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly                onChange={(event, newValue) => {
                  setQualityOfInteractions(newValue);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55,width:"32px",height:"32px" }} fontSize="inherit" />}
              />
              <Typography ml={2}>
              {qualityOfInteractions > 0 ? customIcons[qualityOfInteractions].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Networking Opportunities */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Were there sufficient networking opportunities for your university to engage with other universities and key stakeholders?
            </Typography>
            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center' }}>
              <StyledRating
              required
              size="large"
                name="networking-opportunities"
                value={networkingOpportunities}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly                onChange={(event, newValue) => {
                  setNetworkingOpportunities(newValue);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55,width:"32px",height:"32px" }} fontSize="inherit" />}
              />
              <Typography ml={2}>
              {networkingOpportunities > 0 ? customIcons[networkingOpportunities].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Promotion and Outreach */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              How effective was the promotion and outreach for the event in attracting university attendees?
            </Typography>
            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center' }}>
              <StyledRating
              required
              size="large"
                name="promotion-and-outreach"
                value={promotionAndOutreach}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly                onChange={(event, newValue) => {
                  setPromotionAndOutreach(newValue);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55,width:"32px",height:"32px" }} fontSize="inherit" />}
              />
              <Typography ml={2}>
              {promotionAndOutreach > 0 ? customIcons[promotionAndOutreach].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Information Accessibility */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Was the necessary information about your university easily accessible to attendees?
            </Typography>
            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center' }}>
              <StyledRating
              required
              size="large"
                name="information-accessibility"
                value={informationAccessibility}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly                onChange={(event, newValue) => {
                  setInformationAccessibility(newValue);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55,width:"32px",height:"32px" }} fontSize="inherit" />}
              />
              <Typography ml={2}>
              {informationAccessibility > 0 ? customIcons[informationAccessibility].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Collaborative Opportunities */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Did you identify any potential collaboration opportunities with other universities or organizations during the event?
            </Typography>
            <Box sx={{ justifyContent: 'center',display: 'flex', alignItems: 'center' }}>
            <StyledRating
              name="highlight-selected-only"
              required
              value={collaborativeOpportunities}
              IconContainerComponent={IconContainer}
              getLabelText={(value) => customIcons[value].label}
              highlightSelectedOnly
              onChange={(event, newValue) => {
                setCollaborativeOpportunities(newValue);
              }}
              emptyIcon={<StarIcon style={{ opacity: 0.55,width:"32px",height:"32px" }} fontSize="inherit" />}

            />
              <Typography ml={2}>
              {collaborativeOpportunities > 0 ? customIcons[collaborativeOpportunities].label : ''}
            </Typography>
            </Box>
          </Box>

          {/* Additional Comments */}
          <Box mt={3}>
            <Typography variant="h6" gutterBottom>
              Additional Comments (Feedback/Suggestions/etc....)
            </Typography>
            <TextField
              label="Additional Comments"
              fullWidth
              multiline
              rows={3}
              value={additionalComments}
              onChange={(e) => setAdditionalComments(e.target.value)}
              margin="normal"
              variant="outlined"
            />
          </Box>

          <Box mt={3}>

          {feedback.message && (
                  <Typography
                    variant="body1"
                    style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
                  >
                    {feedback.message}
                  </Typography>
                )}
            <Button  size="large"  disabled={isSubmitting}
          fullWidth type="submit" variant="contained" color="primary">
              Submit
            </Button>
            {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
          </Box>
        </StyledForm>

        </ThemeProvider>
      </div>
      </BackgroundContainer>
          </div>

  );
};

export default FeedbackForm;
