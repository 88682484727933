import React, { useState } from 'react';
import {
  Box, Button, Modal, TextField, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, LinearProgress
} from '@mui/material';
import axios from 'axios';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  zIndex: 1300, // Ensures the modal is above other components
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000"
    },secondary: {
      main: "#000000",
    },
  },
});

const EmailModal = ({ open, handleClose, eventId }) => {
  const [email, setEmail] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start the loading indicator
    try {
      const response = await axios.post('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/registrationEmail', {
        email,
        event_id: eventId // Send event_id along with the email
      });

      if (response.status === 200) {
        setDialogMessage("User exists, and has been registered successfully!");
      } else if (response.status === 404) {
        setDialogMessage("User not found, please register.");
      } else {
        setDialogMessage("An unexpected error occurred.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setDialogMessage("User not found, please register.");
      } else {
        setDialogMessage("An error occurred while submitting the email.");
      }
    } finally {
      setLoading(false); // Stop the loading indicator
      setOpenDialog(true);
      setEmail("");
      handleClose(); // Close the modal before showing the dialog
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleClose();
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-title" variant="h4" component="h2" sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}>
              Enter Your Email
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
                variant="outlined"
                sx={{ mb: 3 }}
              />
              {loading && <LinearProgress sx={{ mb: 2 }} />} {/* Progress line */}
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading} // Disable the button while loading
                color="secondary"
                sx={{
                  backgroundColor: 'secondary.main',
                }}
                
              >
                Submit
              </Button>
            </form>
          </Box>
        </Modal>

        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
          aria-describedby="alert-dialog-slide-description"
          sx={{ zIndex: 1400 }} // Ensure the dialog is above the modal
        >
          <DialogTitle>{"Register/Find By Email"}</DialogTitle>
          <DialogContent>
            <DialogContentText color="success" id="alert-dialog-slide-description">
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default EmailModal;
