import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, LinearProgress, IconButton, Divider, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TrophyModal = ({
  trophyModalOpen,
  handleTrophyClose,
  selectedSchool,
  newTrophy,
  handleNewTrophyChange,
  handleEditSave,
  progress,
  handleTrophyEdit,
}) => {
  const [editingTrophy, setEditingTrophy] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);

  // Check form validity whenever the fields change
  useEffect(() => {
    const isValid = (editingTrophy ? editingTrophy.title : newTrophy.title) &&
                    (editingTrophy ? editingTrophy.name : newTrophy.name) &&
                    (editingTrophy ? editingTrophy.phone : newTrophy.phone) &&
                    (editingTrophy ? editingTrophy.year : newTrophy.year);
    setIsFormValid(isValid);
  }, [editingTrophy, newTrophy]);

  const handleEditClick = (trophy, index) => {
    setEditingTrophy({ ...trophy, index });
  };

  const handleSaveClick = () => {
    if (isFormValid) {
      handleTrophyEdit(editingTrophy);
      setEditingTrophy(null);
    }
  };

  return (
    <Modal
      open={trophyModalOpen}
      onClose={handleTrophyClose}
      aria-labelledby="trophy-modal-title"
      aria-describedby="trophy-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          maxWidth: '600px',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
          maxHeight: '90%',
          overflowY: 'auto',
        }}
      >
        {selectedSchool && (
          <>
            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 3 }}>
              {editingTrophy ? 'Edit Honoring' : 'Add New Honoring'}
            </Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="title"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  value={editingTrophy ? editingTrophy.title : newTrophy.title}
                  onChange={(e) =>
                    editingTrophy
                      ? setEditingTrophy((prev) => ({ ...prev, title: e.target.value }))
                      : handleNewTrophyChange(e)
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={editingTrophy ? editingTrophy.name : newTrophy.name}
                  onChange={(e) =>
                    editingTrophy
                      ? setEditingTrophy((prev) => ({ ...prev, name: e.target.value }))
                      : handleNewTrophyChange(e)
                  }
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  value={editingTrophy ? editingTrophy.phone : newTrophy.phone}
                  onChange={(e) =>
                    editingTrophy
                      ? setEditingTrophy((prev) => ({ ...prev, phone: e.target.value }))
                      : handleNewTrophyChange(e)
                  }
                  required
                  inputProps={{ pattern: '[0-9]*' }}
                  type="tel"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="year"
                  label="Year"
                  variant="outlined"
                  fullWidth
                  value={editingTrophy ? editingTrophy.year : newTrophy.year}
                  onChange={(e) =>
                    editingTrophy
                      ? setEditingTrophy((prev) => ({ ...prev, year: e.target.value }))
                      : handleNewTrophyChange(e)
                  }
                  required
                />
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={editingTrophy ? <EditIcon /> : <AddCircleIcon />}
                onClick={editingTrophy ? handleSaveClick : handleEditSave}
                fullWidth
                sx={{ mr: 1 }}
                disabled={!isFormValid} // Disable button if form is invalid
              >
                {editingTrophy ? 'Save Changes' : 'Add Honoring'}
              </Button>
              {editingTrophy &&
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setEditingTrophy(null)}
                fullWidth
                sx={{ ml: 1 }}
              >
                Cancel
              </Button>
              }
            </Box>

            {progress && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress />
              </Box>
            )}

            <Divider sx={{ my: 4 }} />

            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Existing Honorings
            </Typography>

            {selectedSchool.trophy && selectedSchool.trophy.length > 0 ? (
              selectedSchool.trophy.map((trophy, index) => (
                <Box key={index} sx={{ mb: 2, p: 2, borderRadius: 1, border: '1px solid #ddd' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {trophy.title}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Name:</strong> {trophy.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Phone:</strong> {trophy.phone}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Year:</strong> {trophy.year}
                  </Typography>
                  <Box sx={{ mt: 1, textAlign: 'right' }}>
                    <IconButton
                      onClick={() => handleEditClick(trophy, index)}
                      size="small"
                      color="primary"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography>No honorings found.</Typography>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default TrophyModal;
