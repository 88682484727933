// EmailEditor.js
import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImageDrop } from 'quill-image-drop-module';
//import ImageResize from 'quill-image-resize-module';
import QuillToolbar, { modules, formats } from './EditorToolbar';

//Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

const quillModules = {
  ...modules,
  imageDrop: true,
  //imageResize: {},
};

const EmailEditor = () => {
  const [content, setContent] = useState("This Page is still under construction!!!! DON'T USE ");

  const handleChange = (value) => {
    setContent(value);
  };

  const sendEmail = () => {
    // Send content to the backend using fetch or axios
    fetch("https://edutech-backend-471f7cb69116.herokuapp.com/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Email sent successfully!");
        } else {
          console.error("Failed to send email");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div>
      <QuillToolbar />
      <ReactQuill
        theme="snow"
        value={content}
        onChange={handleChange}
        modules={quillModules}
        formats={formats}
        placeholder="Write something awesome..."
      />
      <button disabled onClick={sendEmail}>Send Email</button>
    </div>
  );
};

export default EmailEditor;

