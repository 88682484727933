import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Modal,
  TextField,
  IconButton,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Collapse,
  Grid,
  Alert,
  LinearProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000",
    },
    secondary: {
      main: "#000000",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});
const HotelReservationsPage = () => {
  const [reservations, setReservations] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [currentReservation, setCurrentReservation] = useState(null);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterType, setFilterType] = useState('');
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialHotel, setInitialHotel] = useState(null); // New state to hold initial event data
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    fetchReservations();
    fetchEvents();
  }, []);

  const fetchReservations = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/allhotelreservations');
      setReservations(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching reservations: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter(event => event.deleted === "0");
      setEvents(filteredEvents);
    } catch (error) {
      setMessage("Error fetching events: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleEditOpen = (reservation) => {
    setCurrentReservation(reservation);
    setInitialHotel(reservation);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentReservation(null);
    setInitialHotel(null);
    setEditOpen(false);
  };

  const handleOpenDeleteDialog = (reservationId) => {
    setReservationToDelete(reservationId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setReservationToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const { _id, ...dataWithoutId } = currentReservation;

    const hasChanged = Object.keys(initialHotel).some(
      key => initialHotel[key] !== currentReservation[key]
    );
    if (hasChanged) {
      setProgress(true);

      try {
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/hotelreservation/${_id}`, dataWithoutId);
        fetchReservations();
        setMessage("Reservation updated successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating reservation: " + error.message);
        setSeverity("error");
        setAlertOpen(true);
      }
      setProgress(false);

    } else {
      handleEditClose();
    }
  };

  const handleDelete = async () => {
    setProgress(true);
    try {
      await axios.delete(`https://edutech-backend-471f7cb69116.herokuapp.com/api/hotelreservation/${reservationToDelete}`);
      fetchReservations();
      setMessage("Reservation deleted successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleCloseDeleteDialog();
    } catch (error) {
      setMessage("Error deleting reservation: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handleInputChange = (field) => (event) => {
    const { value, type, checked } = event.target;
    let updatedReservation = {
      ...currentReservation,
      [field]: type === 'checkbox' ? checked : value,
    };

    if (field === 'shuttleBusNeeded' && !checked) {
      updatedReservation = { ...updatedReservation, flightNumber: '' };
    }

    setCurrentReservation(updatedReservation);
  };

  const handleSelectChange = (field) => (event) => {
    setCurrentReservation({
      ...currentReservation,
      [field]: event.target.value,
    });
  };

  const handleFilterChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleEventFilterChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const filteredReservations = reservations.filter((reservation) => {
    return (
      (filterStatus === '' || reservation.status === filterStatus) &&
      (filterType === '' || reservation.abroad === filterType) &&
      (selectedEvent === '' || reservation.selectedEvent === selectedEvent) &&
      events.some(event => event.id === reservation.selectedEvent)
    );
  });

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredReservations);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reservations');
    XLSX.writeFile(workbook, 'Reservations.xlsx');
  };

  const columns = [
    { field: 'fullName', headerName: 'Full Name', width: 180 },
    { field: 'nationality', headerName: 'Nationality', width: 180 },
    { field: 'institution', headerName: 'Institution', width: 180 },
    { field: 'email', headerName: 'Email', width: 180 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 180 },
    { field: 'checkin', headerName: 'Check-in Date', width: 180 },
    { field: 'checkout', headerName: 'Check-out Date', width: 180 },
    { field: 'singleRooms', headerName: 'Single Rooms', width: 150 },
    { field: 'doubleRooms', headerName: 'Double Rooms', width: 150 },
    { field: 'tripleRooms', headerName: 'Triple Rooms', width: 150 },
    {
      field: 'shuttleBusNeeded',
      headerName: 'Shuttle Bus',
      width: 150,
      renderCell: (params) => (params.value ? 'Yes' : 'No')
    },
    { field: 'flightNumber', headerName: 'Flight Number', width: 180 },
    {
      field: 'selectedEvent',
      headerName: 'Event Name',
      width: 180,
      renderCell: (params) => events.find(event => event.id === params.value)?.name || 'Unknown Event'
    },
    {
      field: 'abroad',
      headerName: 'Type',
      width: 150,
      renderCell: (params) => params.value === "1" ? 'Abroad' : 'Egypt'
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => params.value === '1' ? <CheckCircleIcon style={{ color: 'green' }} /> : <CancelIcon style={{ color: 'red' }} />
    },
    {
      field: 'actions',
      headerName: 'Tools',
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(params.row._id)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  return (
    <div>
          <ThemeProvider theme={theme}>

      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      <Box sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={filterStatus}
                onChange={handleFilterChange(setFilterStatus)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0">Not Handled</MenuItem>
                <MenuItem value="1">Handled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={filterType}
                onChange={handleFilterChange(setFilterType)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0">Egypt</MenuItem>
                <MenuItem value="1">Abroad</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Event</InputLabel>
              <Select
                label="Event"
                value={selectedEvent}
                onChange={handleEventFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                {events.map(event => (
                  <MenuItem key={event.id} value={event.id}>
                    {event.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3} sx={{ textAlign: 'right' }}>
            <Button variant="contained" color="success" onClick={handleExportToExcel} sx={{ width: '100%' }}>
              Export to Excel
            </Button>
          </Grid>
        </Grid>
      </Box>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={filteredReservations}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 30]}
          checkboxSelection
          getRowId={(row) => row._id}
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
        />
      </Box>
      {currentReservation && (
         <Modal open={editOpen} onClose={handleEditClose}> 
         <Box
           sx={{
             position: 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             maxHeight: '90%',
             overflowY: 'auto',
             bgcolor: 'background.paper',
             border: '2px solid #000',
             boxShadow: 24,
             borderRadius: 2,
             p: 4,
             width: '90%',
             maxWidth: 600
           }}
         >
           <Box
             component="form"
             onSubmit={handleEditSubmit}
             sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
           >
             <h2>Edit Reservation</h2>
             <TextField
               label="Full Name"
               value={currentReservation.fullName}
               onChange={handleInputChange('fullName')}
               fullWidth
               margin="normal"
             />
             <TextField
               label="Institution"
               value={currentReservation.institution}
               onChange={handleInputChange('institution')}
               fullWidth
               margin="normal"
             />
             <TextField
               label="Email"
               value={currentReservation.email}
               onChange={handleInputChange('email')}
               fullWidth
               margin="normal"
             />
             <TextField
               label="Phone Number"
               value={currentReservation.phoneNumber}
               onChange={handleInputChange('phoneNumber')}
               fullWidth
               margin="normal"
             />
             <TextField
               label="Nationality"
               value={currentReservation.nationality}
               onChange={handleInputChange('nationality')}
               fullWidth
               margin="normal"
             />
             <LocalizationProvider dateAdapter={AdapterDayjs}>
               <DateTimePicker
                 label="Check-in Date"
                 value={dayjs(currentReservation.checkin)}
                 onChange={(newValue) => setCurrentReservation({ ...currentReservation, checkin: newValue.format('YYYY-MM-DD') })}
                 renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
               />
               <DateTimePicker
                 label="Check-out Date"
                 value={dayjs(currentReservation.checkout)}
                 onChange={(newValue) => setCurrentReservation({ ...currentReservation, checkout: newValue.format('YYYY-MM-DD') })}
                 renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
               />
             </LocalizationProvider>
             <TextField
               label="Single Rooms"
               type="number"
               value={currentReservation.singleRooms}
               onChange={handleInputChange('singleRooms')}
               fullWidth
               margin="normal"
               inputProps={{ min: '0' }}
             />
             <TextField
               label="Double Rooms"
               type="number"
               value={currentReservation.doubleRooms}
               onChange={handleInputChange('doubleRooms')}
               fullWidth
               margin="normal"
               inputProps={{ min: '0' }}
             />
             <TextField
               label="Triple Rooms"
               type="number"
               value={currentReservation.tripleRooms}
               onChange={handleInputChange('tripleRooms')}
               fullWidth
               margin="normal"
               inputProps={{ min: '0' }}
             />
             <FormControlLabel
               control={
                 <Switch
                   checked={currentReservation.shuttleBusNeeded}
                   onChange={handleInputChange('shuttleBusNeeded')}
                 />
               }
               label="Shuttle Bus Needed"
               sx={{ display: currentReservation.abroad === "1" ? 'block' : 'none' }}
             />
             {currentReservation.shuttleBusNeeded && (
               <TextField
                 label="Flight Number"
                 value={currentReservation.flightNumber}
                 onChange={handleInputChange('flightNumber')}
                 fullWidth
                 margin="normal"
               />
             )}
             <FormControl fullWidth margin="normal">
               <InputLabel>Type</InputLabel>
               <Select
                 label="Type"
                 value={currentReservation.abroad}
                 onChange={handleSelectChange('abroad')}
               >
                 <MenuItem value={"0"}>Egypt</MenuItem>
                 <MenuItem value={"1"}>Abroad</MenuItem>
               </Select>
             </FormControl>
             <FormControl fullWidth margin="normal">
               <InputLabel>Status</InputLabel>
               <Select
                 label="Status"
                 value={currentReservation.status}
                 onChange={handleSelectChange('status')}
               >
                 <MenuItem value={"0"}>Not Handled</MenuItem>
                 <MenuItem value={"1"}>Handled</MenuItem>
               </Select>
             </FormControl>
             <FormControl fullWidth margin="normal">
               <InputLabel>Event</InputLabel>
               <Select
                 label="Event"
                 value={currentReservation.selectedEvent}
                 onChange={handleSelectChange('selectedEvent')}
               >
                 {events.map(event => (
                   <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
                 ))}
               </Select>
             </FormControl>
             <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
               Submit
             </Button>
           </Box>
         </Box>
       </Modal>
      )}
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Reservation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this reservation? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button color="error"  onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default HotelReservationsPage;
