import React from 'react';
import { Autocomplete, TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchComponent = ({ searchQuery, handleSearchChange, searchOptions = [] }) => {
  return (
    <Autocomplete
      freeSolo
      options={searchOptions} // Ensure searchOptions defaults to an empty array
      value={searchQuery}
      onInputChange={(event, newInputValue) => handleSearchChange(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search by Phone or School Name..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <IconButton>
                  <SearchIcon />
                </IconButton>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{ backgroundColor: 'white', borderRadius: 1, margin: 1 }}
          fullWidth
        />
      )}
    />
  );
};

export default SearchComponent;
