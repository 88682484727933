// TalentForm.js
import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, InputLabel, Select, MenuItem, FormControl, Chip, InputAdornment, IconButton, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import axios from 'axios';
import LinearProgress from '@mui/material/LinearProgress';

const TalentForm = ({ open, handleClose, fetchNews, setAlertOpen, setMessage, setSeverity }) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [memberName, setMemberName] = useState('');
  const [currentTalent, setCurrentTalent] = useState({speakers:[]});
  const [progress, setProgress] = useState(false);

const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      setProgress(true);
      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/addagenda', currentTalent, {
        headers: {
            'Content-Type': 'application/json',
        }
      });

      if (response.status === 201) {
        fetchNews();
        handleCloseModal();
        setMessage("Agenda Added successfully");
        setSeverity("success");
        setAlertOpen(true);
      } else {
        handleCloseModal();
        setMessage("Error adding Agenda");
        setSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      handleCloseModal();
      setMessage("Error adding Agenda");
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };


  const formatTime = (startTime, endTime) => {
    if (!startTime) return '';

    const formattedStart = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedEnd = endTime ? endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '';
    return endTime ? `${formattedStart} - ${formattedEnd}` : formattedStart;
  };

  const handleStartTimeChange = (newStartTime) => {
    setStartTime(newStartTime);
    const formattedTime = formatTime(newStartTime, endTime);
    setCurrentTalent((prevState) => ({ ...prevState, time: formattedTime }));
  };

  const handleEndTimeChange = (newEndTime) => {
    setEndTime(newEndTime);
    const formattedTime = formatTime(startTime, newEndTime);
    setCurrentTalent((prevState) => ({ ...prevState, time: formattedTime }));
  };

  const handleAddMember = () => {
    if (memberName.trim() !== '') {
      const updatedTeamMembers = [...currentTalent.speakers, memberName.trim()];
      setCurrentTalent((prev) => ({ ...prev, speakers: updatedTeamMembers }));
      setMemberName('');
    }
  };

  const handleDeleteMember = (memberToDelete) => {
    const updatedTeamMembers = currentTalent.speakers.filter(member => member !== memberToDelete);
    setCurrentTalent((prev) => ({ ...prev, speakers: updatedTeamMembers }));
  };
  const handleCloseModal = () => {
    handleClose();
    setCurrentTalent({speakers:[]});
    setStartTime(null);
    setEndTime(null);
    setMemberName('');
    setProgress(false);
  };


  return (
    <div>
     
    <Modal
open={open}
onClose={handleCloseModal}
aria-labelledby="modal-title"
aria-describedby="modal-description"
sx={{ zIndex: 1300 }} // Ensure the modal is below the dialog
>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        component="form"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: 'auto',
          maxHeight: '90%',
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h6" component="h2" gutterBottom>
           Add New Section
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Year"
              fullWidth
              type="number"
              required
              value={currentTalent.year || ''}
              onChange={(e) => setCurrentTalent({ ...currentTalent, year: e.target.value })}
              InputProps={{ inputProps: { min: 1900, max: new Date().getFullYear(), step: 1 } }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Day</InputLabel>
              <Select
                value={currentTalent.day || ''}
                onChange={(e) => setCurrentTalent({ ...currentTalent, day: e.target.value })}
                label="Day"
              >
                <MenuItem value="Day 1">Day 1</MenuItem>
                <MenuItem value="Day 2">Day 2</MenuItem>
                <MenuItem value="Day 3">Day 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TimePicker
              label="Start Time"
              value={startTime}
              onChange={handleStartTimeChange}
              renderInput={(params) => <TextField {...params} fullWidth required />}
              fullWidth={true}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              sx={{width: '100%'}}
              textFieldStyle={{width: '100%'}}    
            />
          </Grid>
          <Grid item xs={12}>
            <TimePicker
              label="End Time (Optional)"
              value={endTime}
              onChange={handleEndTimeChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
              fullWidth={true}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              sx={{width: '100%'}}
              textFieldStyle={{width: '100%'}}    
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Time"
              fullWidth
              value={currentTalent.time || ''}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Session"
              fullWidth
              required
              value={currentTalent.session || ''}
              onChange={(e) => setCurrentTalent({ ...currentTalent, session: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Add Speaker"
                fullWidth
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleAddMember()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="add speaker" onClick={handleAddMember}>
                        <AddIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box display="flex" flexWrap="wrap" gap={1}>
                {currentTalent.speakers?.map((member, index) => (
                  <Chip
                    key={index}
                    label={member}
                    onDelete={() => handleDeleteMember(member)}
                    style={{ margin: '2px' }}
                  />
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Moderator"
              fullWidth
              value={currentTalent.moderator || ''}
              onChange={(e) => setCurrentTalent({ ...currentTalent, moderator: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              fullWidth
              multiline
              rows={4}
              value={currentTalent.description || ''}
              onChange={(e) => setCurrentTalent({ ...currentTalent, description: e.target.value })}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={currentTalent.status || ''}
                onChange={(e) => setCurrentTalent({ ...currentTalent, status: e.target.value })}
              >
                <MenuItem value="0">Not Published</MenuItem>
                <MenuItem value="1">Published</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button variant="contained" type="submit" fullWidth sx={{ mt: 2 }}>
          Save Changes
        </Button>
        {progress && (
             <Box sx={{ width: '100%', mt: 2 }}>
               <LinearProgress />
             </Box>
           )}
      </Box>
    </LocalizationProvider>
     
    </Modal>
    </div>

  );
};

export default TalentForm;
