import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FormControl, InputLabel, MenuItem, Select, Typography, Checkbox, Chip, Paper, TextField } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import dayjs from 'dayjs'; // Add dayjs for date parsing

export default function DataGridDemo() {
    const [participants, setParticipants] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('all');
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState([]);
    const [totalAttendees, setTotalAttendees] = useState(0);
    const [availableEvents, setAvailableEvents] = useState([]);
    const [progress, setProgress] = useState(false);
    const [ageFilterApplied, setAgeFilterApplied] = useState(false);
    const [invalidBirthdatesCount, setInvalidBirthdatesCount] = useState(0);
    const [ageComparisonType, setAgeComparisonType] = useState('greater_than'); // Added state for age comparison type
    const [ageValue, setAgeValue] = useState(''); // Added state for age value
  useEffect(() => {
    const fetchAvailableEvents = async () => {
      try {
        const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
        const filteredEvents = response.data.filter(event => event.deleted === "0");
        setAvailableEvents(response.data.reverse());
      } catch (error) {
        console.error('Error fetching available events:', error);
      }
    };

    fetchAvailableEvents();
  }, []);

  useEffect(() => {
    const fetchParticipants = async () => {
      setProgress(true);
      let apiUrl = `https://edugate-backend-b5ad4c02888d.herokuapp.com/api/${selectedEvent === 'all' ? 'getallvisitors' : `${selectedEvent}/visitors`}`;
      try {
        const response = await axios.get(apiUrl);
        const data = response.data;
        if (data.length > 0) {
          const mappedData = data.map(row => ({
            id: row.id,
            f_name: row.f_name,
            l_name: row.l_name,
            email: row.email,
            mobile: row.mobile,
            city: row.city,
            country: row.country,
            gender: row.gender,
            birthdate: row.birth_date,
            visitor_type: row.visitor_type,
            school_name: row.school_name,
            education_type: row.education_type,
            grade: row.grade,
            studyField: row.studyField,
            is_studying_abroad: row.is_studying_abroad,
            studying_abroad_destination: row.studying_abroad_destination,
            postgraduate_looking_for: row.postgraduate_looking_for,
            hear_aboutus_at: row.hear_aboutus_at,
            budget_dollars:row.budget_dollars,
            budget_EGP:row.budget_EGP,
            date: row.date
          }));
          setParticipants(mappedData.reverse());
        }
      } catch (error) {
        console.error('Error fetching participants:', error);
      }
      setProgress(false);
    };

    fetchParticipants();
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedEvent !== 'all') {
      const fetchAttendees = async () => {
        try {
          const response = await axios.get(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/${selectedEvent}/attendeees`);
          const data = response.data.total_attendees;
          setTotalAttendees(data);
        } catch (error) {
          console.error('Error fetching attendees:', error);
        }
      };

      fetchAttendees();
    }
  }, [selectedEvent]);
  const parseBirthdate = (birthdate) => {
    const formats = ['YYYY-MM-DDTHH:mm:ss', 'MM/DD/YYYY'];
    const parsedDate = dayjs(birthdate, formats, true);
    return parsedDate.isValid() ? parsedDate : null;
};


  const countInvalidBirthdates = (data) => {
    return data.reduce((count, row) => {
      return parseBirthdate(row.birthdate) === null ? count + 1 : count;
    }, 0);
  };

  const applyFilters = () => {
    const filteredData = participants.filter(row => {
        const birthdate = parseBirthdate(row.birthdate);
        const age = birthdate ? dayjs().diff(birthdate, 'year') : null;
        const ageValueNumber = Number(ageValue);

        const ageFilterCondition = ageFilterApplied ? (
          (ageComparisonType === 'greater_than' && age >= ageValueNumber) ||
          (ageComparisonType === 'less_than' && age < ageValueNumber) ||
          (ageComparisonType === 'equal_to' && age === ageValueNumber)
      ) : true;
      

        return (
            ageFilterCondition &&
            (selectedGrades.length === 0 || selectedGrades.map(g => g.toLowerCase()).includes((row.grade || '').toLowerCase())) &&
            (selectedEducation.length === 0 || selectedEducation.map(e => e.toLowerCase()).includes((row.education_type || '').toLowerCase())) &&
            (selectedUserType.length === 0 || selectedUserType.map(u => u.toLowerCase()).includes((row.visitor_type || '').toLowerCase()))
        );
    });

    setFilteredParticipants(filteredData);

    if (ageFilterApplied) {
        const invalidCount = countInvalidBirthdates(filteredData);
        setInvalidBirthdatesCount(invalidCount);
    }
};


useEffect(() => {
  if(ageValue ===""){
    setAgeFilterApplied(false)
  }else{
    setAgeFilterApplied(true)
  }
  console.log('Age Filter Applied:', ageFilterApplied);
  console.log('Age Comparison Type:', ageComparisonType);
  console.log('Age Value:', ageValue);

  applyFilters();
}, [selectedGrades, selectedEducation, selectedUserType, participants, ageFilterApplied, ageComparisonType, ageValue]);


  const exportToExcel = () => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'edugate_registrations';
    const ws = XLSX.utils.json_to_sheet(filteredParticipants);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleGradeChange = (event) => {
    const { target: { value } } = event;
    setSelectedGrades(typeof value === 'string' ? value.split(',') : value);
  };

  const handleSelectedUserTypeChange = (event) => {
    const { target: { value } } = event;
    setSelectedUserType(typeof value === 'string' ? value.split(',') : value);
  };

  const handleEducationChange = (event) => {
    const { target: { value } } = event;
    setSelectedEducation(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <Box sx={{ padding: 3, backgroundColor: 'white', borderRadius: 2, boxShadow: 3, mb: 3 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Button onClick={exportToExcel} variant="contained" color="success" sx={{ fontSize: '16px', py: 1.5 }}>
            Export Data to Excel
          </Button>
        </Box>
  
        <Box sx={{ textAlign: 'center', mb: 4, display: 'flex', justifyContent: 'center', gap: 3 }}>
          <Paper elevation={4} sx={{ padding: '20px 30px', borderRadius: 2, backgroundColor: '#ffffff' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
              Total Registrations
            </Typography>
            <Typography variant="h3" color="primary">
              {filteredParticipants.length}
            </Typography>
          </Paper>
  
          {selectedEvent !== 'all' && (
            <Paper elevation={4} sx={{ padding: '20px 30px', borderRadius: 2, backgroundColor: '#ffffff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                Total Attendees
              </Typography>
              <Typography variant="h3" color="primary">
                {totalAttendees}
              </Typography>
            </Paper>
          )}
          {ageFilterApplied && (
            <Paper elevation={4} sx={{ padding: '20px 30px', borderRadius: 2, backgroundColor: '#ffffff' }}>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                Invalid Birthdates
              </Typography>
              <Typography variant="h3" color="error">
                {invalidBirthdatesCount}
              </Typography>
            </Paper>
          )}
        </Box>
  
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, justifyContent: 'center', mb: 4 }}>
          {/* Event Filter */}
          <FormControl sx={{ minWidth: 240 }}>
            <InputLabel>Choose Event</InputLabel>
            <Select
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
              label="Choose Event"
            >
              {availableEvents.map((event) => (
                <MenuItem key={event.id} value={event.id}>
                  {event.name}
                </MenuItem>
              ))}
              <MenuItem value="all">Show All</MenuItem>
            </Select>
          </FormControl>
  
          {/* Education Type Filter */}
          <FormControl sx={{ minWidth: 240 }}>
            <InputLabel>Education Type</InputLabel>
            <Select
              multiple
              value={selectedEducation}
              onChange={handleEducationChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              label="Education Type"
            >
              <MenuItem value="American Diploma">
                <Checkbox checked={selectedEducation.includes('American Diploma')} />
                <ListItemText primary="American Diploma" />
              </MenuItem>
              <MenuItem value="IGCSE Diploma">
                <Checkbox checked={selectedEducation.includes('IGCSE Diploma')} />
                <ListItemText primary="IGCSE Diploma" />
              </MenuItem>
              <MenuItem value="Thanaweya Amma">
                <Checkbox checked={selectedEducation.includes('Thanaweya Amma')} />
                <ListItemText primary="Thanaweya Amma" />
              </MenuItem>
              <MenuItem value="Canadian Diploma">
                <Checkbox checked={selectedEducation.includes('Canadian Diploma')} />
                <ListItemText primary="Canadian Diploma" />
              </MenuItem>
              <MenuItem value="International Baccalaureate Diploma">
                <Checkbox checked={selectedEducation.includes('International Baccalaureate Diploma')} />
                <ListItemText primary="International Baccalaureate Diploma" />
              </MenuItem>
              <MenuItem value="French Baccalaureate Diploma">
                <Checkbox checked={selectedEducation.includes('French Baccalaureate Diploma')} />
                <ListItemText primary="French Baccalaureate Diploma" />
              </MenuItem>
              <MenuItem value="German Abitur">
                <Checkbox checked={selectedEducation.includes('German Abitur')} />
                <ListItemText primary="German Abitur" />
              </MenuItem>
              <MenuItem value="BTEC">
                <Checkbox checked={selectedEducation.includes('BTEC')} />
                <ListItemText primary="BTEC" />
              </MenuItem>
              <MenuItem value="WAEC">
                <Checkbox checked={selectedEducation.includes('WAEC')} />
                <ListItemText primary="WAEC" />
              </MenuItem>
              <MenuItem value="Other">
                <Checkbox checked={selectedEducation.includes('Other')} />
                <ListItemText primary="Other" />
              </MenuItem>
            </Select>
          </FormControl>
  
          {/* Grade Filter */}
          <FormControl sx={{ minWidth: 240 }}>
            <InputLabel>Grade</InputLabel>
            <Select
              multiple
              value={selectedGrades}
              onChange={handleGradeChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              label="Grade"
            >
              <MenuItem value="Grade 10">
                <Checkbox checked={selectedGrades.includes('Grade 10')} />
                <ListItemText primary="Grade 10" />
              </MenuItem>
              <MenuItem value="Grade 11">
                <Checkbox checked={selectedGrades.includes('Grade 11')} />
                <ListItemText primary="Grade 11" />
              </MenuItem>
              <MenuItem value="Grade 12">
                <Checkbox checked={selectedGrades.includes('Grade 12')} />
                <ListItemText primary="Grade 12" />
              </MenuItem>
            </Select>
          </FormControl>
  
          {/* Visitor Type Filter */}
          <FormControl sx={{ minWidth: 240 }}>
            <InputLabel>Visitor Type</InputLabel>
            <Select
              multiple
              value={selectedUserType}
              onChange={handleSelectedUserTypeChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              label="Visitor Type"
            >
              <MenuItem value="parent">
                <Checkbox checked={selectedUserType.includes('parent')} />
                <ListItemText primary="Parent" />
              </MenuItem>
              <MenuItem value="postGraduate">
                <Checkbox checked={selectedUserType.includes('postGraduate')} />
                <ListItemText primary="PostGraduate" />
              </MenuItem>
              <MenuItem value="underGraduate">
                <Checkbox checked={selectedUserType.includes('underGraduate')} />
                <ListItemText primary="UnderGraduate" />
              </MenuItem>
            </Select>
          </FormControl>
  
          {/* Age Filter */}
          <FormControl sx={{ minWidth: 240 }}>
            <InputLabel>Age Filter</InputLabel>
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Select
                value={ageComparisonType}
                onChange={(e) => setAgeComparisonType(e.target.value)}
                label="Age Comparison"
                sx={{ width: '50%' }}
              >
                <MenuItem value="greater_than">Greater Than or Equal</MenuItem>
                <MenuItem value="less_than">Less Than</MenuItem>
                <MenuItem value="equal_to">Equal To</MenuItem>
              </Select>
              <TextField
                type="number"
                value={ageValue}
                onChange={(e) => setAgeValue(e.target.value)}
                label="Age"
                sx={{ width: '50%' }}
              />
            </Box>
          </FormControl>
        </Box>
      </Box>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <DataGrid 
        autoHeight
        rows={filteredParticipants}
        pageSize={20}
        columns={[
          { field: 'id', headerName: 'ID', width: 100 },
          { field: 'f_name', headerName: 'First Name', width: 150 },
          { field: 'l_name', headerName: 'Last Name', width: 150 },
          { field: 'email', headerName: 'Email', width: 200 },
          { field: 'mobile', headerName: 'Mobile', width: 150 },
          { field: 'city', headerName: 'City', width: 150 },
          { field: 'country', headerName: 'Country', width: 150 },
          { field: 'gender', headerName: 'Gender', width: 150 },
          { field: 'birthdate', headerName: 'Birthdate', width: 150 },
          { field: 'visitor_type', headerName: 'Visitor Type', width: 150 },
          { field: 'school_name', headerName: 'School Name', width: 150 },
          { field: 'education_type', headerName: 'Education Type', width: 150 },
          { field: 'grade', headerName: 'Grade', width: 150 },
          { field: 'studyField', headerName: 'Study Field', width: 150 },
          { field: 'is_studying_abroad', headerName: 'Studying Abroad', width: 150 },
          { field: 'studying_abroad_destination', headerName: 'Studying Abroad Destination', width: 150 },
          { field: 'budget_dollars', headerName: 'Budget in Dollars', width: 150 },
          { field: 'budget_EGP', headerName: 'Budget in EGP', width: 150 },
          { field: 'postgraduate_looking_for', headerName: 'PostGraduate Looking For', width: 150 },
          { field: 'hear_aboutus_at', headerName: 'Hear about us at', width: 150 },
          { field: 'date', headerName: 'Date', width: 150 }
        ]}
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}  