import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Typography, Box, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Paper, IconButton } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, LineChart, Line, PieChart, Pie, Cell } from 'recharts';
import LinearProgress from '@mui/material/LinearProgress';
import { Skeleton } from '@mui/material';

import html2canvas from 'html2canvas';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

function Home() {
  const [registrations, setRegistrations] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('all');
  const [availableEvents, setAvailableEvents] = useState([]);
  const [progress, setProgress] = useState(false);

  const snapshotRef = useRef(null);

  useEffect(() => {
    fetchAvailableEvents();
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      fetchRegistrations(selectedEvent);
      fetchAttendees(selectedEvent);
      const intervalId = setInterval(() => {
        fetchRegistrations(selectedEvent);
        fetchAttendees(selectedEvent);
      }, 60000); // Fetch data every minute

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [selectedEvent]);

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.reverse();
      setAvailableEvents(filteredEvents);
    } catch (error) {
      console.error('Error fetching available events:', error);
    }
  };

  const fetchRegistrations = async (eventId) => {
    try {
      setProgress(true);
      const response = await axios.get(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/${selectedEvent === 'all' ? 'getallvisitors' : `${selectedEvent}/visitors`}`);
      const formattedData = response.data.map(entry => ({
        ...entry,
        date: formatDate(entry.date)
      }));
      setRegistrations(formattedData);
    } catch (error) {
      console.error('Error fetching registrations:', error);
    }
    setProgress(false);
  };

  const fetchAttendees = async (eventId) => {
    try {
      const response = await axios.get(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/${selectedEvent === 'all' ? 'getallattendees' : `${selectedEvent}/attendees`}`);
      setAttendees(response.data);
    } catch (error) {
      console.error('Error fetching attendees:', error);
    }
  };

  const formatDate = (dateString) => {
    try {
      if (dateString.includes("/")) {
        const [datePart, timePart] = dateString.split(" @ ");
        const [day, month, year] = datePart.split("/");
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      } else {
        const [datePart, timePartWithMeridian] = dateString.split(" ");
        let [timePart, meridian] = timePartWithMeridian.match(/(\d{2}:\d{2}:\d{2})(am|pm)/i).slice(1, 3);
        const [year, month, day] = datePart.split("-");
        let [hours, minutes, seconds] = timePart.split(":");

        if (meridian.toLowerCase() === "pm" && hours !== "12") {
          hours = String(Number(hours) + 12).padStart(2, '0');
        } else if (meridian.toLowerCase() === "am" && hours === "12") {
          hours = "00";
        }

        return `${year}-${month}-${day}`;
      }
    } catch (error) {
      console.error('Error formatting date:', error, dateString);
      return dateString;
    }
  };

  // Helper function to calculate the linear regression (slope and intercept)
const calculateTrendline = (data, xKey, yKey) => {
  let n = data.length;
  let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;

  data.forEach((point) => {
    let x = new Date(point[xKey]).getTime(); // Convert date to timestamp
    let y = point[yKey];
    sumX += x;
    sumY += y;
    sumXY += x * y;
    sumX2 += x * x;
  });

  let slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
  let intercept = (sumY - slope * sumX) / n;

  // Map the trendline values back onto the xKey (dates)
  return data.map(point => ({
    [xKey]: point[xKey],
    trend: slope * new Date(point[xKey]).getTime() + intercept,
  }));
};

  const aggregateDataByDate = (data) => {
    const dateCounts = data.reduce((acc, entry) => {
      const date = entry.date;
      if (date) {
        acc[date] = (acc[date] || 0) + 1;
      }
      return acc;
    }, {});

    const aggregatedData = Object.keys(dateCounts).map(date => ({
      date,
      count: dateCounts[date]
    }));

    // Sort the data by date
    aggregatedData.sort((a, b) => new Date(a.date) - new Date(b.date));

    return aggregatedData;
  };

  const renderLineChart = (data, dataKey, strokeColor, name) => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={dataKey} name={name} stroke={strokeColor} />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const renderBarChart = (dataKey, name) => {
    const counts = registrations.reduce((acc, entry) => {
      const value = entry[dataKey]?.toLowerCase(); // Convert to lowercase
      if (value) {
        acc[value] = (acc[value] || 0) + 1;
      }
      return acc;
    }, {});

    const data = Object.keys(counts)
      .filter(value => counts[value] >= 2) // Filter out counts less than 2
      .map(value => ({
        [dataKey]: value,
        count: counts[value]
      }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis dataKey={dataKey} />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" name={`Total Users (${name})`} fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  };

  const renderPieChart = (registrationCount, attendeeCount) => {
    const data = [
      { name: 'Registrations', value: registrationCount },
      { name: 'Attendees', value: attendeeCount },
    ];

    const COLORS = ['#8884d8', '#82ca9d'];

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={100}
            fill="#8884d8"
            label
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const totalRegistrations = registrations.length;
  const totalAttendees = attendees.length;

  const takeSnapshot = () => {
    if (snapshotRef.current) {
      html2canvas(snapshotRef.current).then(canvas => {
        const link = document.createElement('a');
        const filename = `${selectedEvent}-${new Date().toISOString()}.png`;
        link.href = canvas.toDataURL('image/png');
        link.download = filename;
        link.click();
      });
    }
  };
  
  return (
    <div>
      {/* Snapshot Button at the Top */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
        <IconButton color="primary" onClick={takeSnapshot}>
          <CameraAltIcon />
        </IconButton>
      </Box>

      <Typography variant="h4" align="center" gutterBottom>
        Some Statistics about EDU GATE
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4, mt: 2 }}>
        <FormControl fullWidth sx={{ maxWidth: 400 }}>
          <InputLabel>Choose Event:</InputLabel>
          <Select
            value={selectedEvent}
            onChange={(e) => setSelectedEvent(e.target.value)}
            label="Choose Event"
            sx={{ backgroundColor: 'white', borderRadius: 1, boxShadow: 1 }}
          >
            {availableEvents.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                {event.name}
              </MenuItem>
            ))}
            <MenuItem value="all">Show All</MenuItem>
          </Select>
        </FormControl>

        {progress && (
  <Box sx={{ width: '100%', maxWidth: 400 }}>
    <LinearProgress />
  </Box>
)}

        {selectedEvent && (
          <Box sx={{ width: '100%', maxWidth: 1200, mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, mb: 4 }}>
              <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', width: '100%', maxWidth: 400 }}>
                <Typography variant="h6" gutterBottom>
                  Total Registrations
                </Typography>
                <Typography variant="h4" color="primary">
                  {totalRegistrations}
                </Typography>
              </Paper>

              {selectedEvent !== 'all' && (
                <Paper elevation={3} sx={{ padding: 3, textAlign: 'center', width: '100%', maxWidth: 400 }}>
                  <Typography variant="h6" gutterBottom>
                    Total Attendees
                  </Typography>
                  <Typography variant="h4" color="primary">
                    {totalAttendees}
                  </Typography>
                </Paper>
              )}
            </Box>

            {/* Growth of Registrations Over Time in a Single Row */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Card elevation={3} sx={{ width: '100%', maxWidth: 1200 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Growth of Registrations Over Time
                  </Typography>
                  {renderLineChart(aggregateDataByDate(registrations), 'count', '#8884d8', 'Registrations')}
                </CardContent>
              </Card>
            </Box>

            {/* Remaining Charts in Two-Per-Row Layout */}
            <Box ref={snapshotRef} sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))', gap: 3 }}>
              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Number of Attendees Compared to Registrations
                  </Typography>
                  {renderPieChart(totalRegistrations, totalAttendees)}
                </CardContent>
              </Card>

              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Distribution of Registrations by Education Type
                  </Typography>
                  {renderBarChart('education_type', 'Education Type')}
                </CardContent>
              </Card>

              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Distribution of Registrations by Visitor Type
                  </Typography>
                  {renderBarChart('visitor_type', 'Visitor Type')}
                </CardContent>
              </Card>

              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Distribution of Registrations by Grade
                  </Typography>
                  {renderBarChart('grade', 'Grade')}
                </CardContent>
              </Card>

              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Distribution of Registrations by Studying Abroad Destination
                  </Typography>
                  {renderBarChart('studying_abroad_destination', 'Studying Abroad Destination')}
                </CardContent>
              </Card>

              <Card elevation={3}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Distribution of Registrations by How They Heard About Us
                  </Typography>
                  {renderBarChart('hear_aboutus_at', 'How They Heard About Us')}
                </CardContent>
              </Card>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default Home;
