import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Container, Grid, Card, CardMedia, CardContent, LinearProgress, Typography, Select, MenuItem, FormControl, InputLabel, OutlinedInput, Box, Button } from '@mui/material';
import { jsPDF } from "jspdf";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { styled } from '@mui/system';

const EventSchedulePage = () => {
  const [events, setEvents] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [allSpeakers, setAllSpeakers] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [progress, setProgress] = useState(false);
  const [dates, setDates] = useState([]);
  const [slots, setSlots] = useState([]);

  const StyledTitle = styled(Typography)(({ theme }) => ({
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'bold',
    fontSize: '2.5rem', // Adjust size as needed
    color: 'transparent',
    background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    position: 'relative',
    display: 'inline-block',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: '#FF6347', // Accent color for underline
      transform: 'scaleX(0)',
      transformOrigin: 'bottom right',
      transition: 'transform 0.3s ease-out',
    },
    '&:hover::after': {
      transform: 'scaleX(1)',
      transformOrigin: 'bottom left',
    },
  }));
  // Fetch events and speakers
  useEffect(() => {
    const fetchData = async () => {
        setProgress(true);
      try {
        const eventResponse = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
        setEvents(eventResponse.data.reverse());

        const speakerResponse = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/stage_data');
        setAllSpeakers(speakerResponse.data.filter(speaker => speaker.status === "1"));
      } catch (error) {
        console.error("Error fetching data: ", error.message);
      }
      setProgress(false);

    };

    fetchData();
  }, []);

  // Update dates and slots based on selected event
  useEffect(() => {
    if (selectedEvent) {
      const filteredSpeakers = allSpeakers.filter(speaker => speaker.event_id === selectedEvent);

      setDates(Array.from(new Set(filteredSpeakers.map(speaker => speaker.date))).sort());
      setSlots(Array.from(new Set(filteredSpeakers.map(speaker => speaker.slot))).sort());

      // Filter speakers based on selected date and time
      const filtered = filteredSpeakers.filter(speaker =>
        (!selectedDate || speaker.date === selectedDate) &&
        (!selectedTime || speaker.slot === selectedTime)
      );

      setSpeakers(filtered);
    } else {
      setDates([]);
      setSlots([]);
      setSpeakers([]);
    }
  }, [selectedEvent, selectedDate, selectedTime, allSpeakers]);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    setSelectedDate('');
    setSelectedTime('');
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000"
      }
    }
  });

  const getImageData = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;  // Handle read errors
        reader.readAsDataURL(blob);
    });
};

// Function to generate the PDF
const generatePDF = async () => {
    try {
        // Create a new jsPDF instance
        const pdf = new jsPDF();

        // Add the logo to the PDF
        const logoUrl = './edutech_logo.png';  // Adjust the path to your logo
        const response = await fetch(logoUrl);
        if (!response.ok) throw new Error('Failed to load logo');
        const logo = await response.blob();
        const logoImageData = await getImageData(logo);
        pdf.addImage(logoImageData, 'PNG', 10, 10, 60, 30);  // Adjusted height and width for the logo

        // Set up the title
        pdf.setFontSize(22);
        pdf.setTextColor(139, 0, 0); // Dark red color for the title
        pdf.text("Stage Schedule", pdf.internal.pageSize.getWidth() / 2, 45, null, null, "center");

        // Define styles for the content
        const headerFontSize = 14;
        const textFontSize = 12;
        const lineHeight = 10;
        const margin = 20;
        let yOffset = 50; // Start below the logo

        // Line styling
        const drawLine = () => {
            pdf.setDrawColor(139, 0, 0); // Dark red color for lines
            pdf.setLineWidth(0.5);
            pdf.line(margin, yOffset, pdf.internal.pageSize.getWidth() - margin, yOffset);
            yOffset += lineHeight;  // Move down for next content
        };

        // Sorting the speakers
        const sortedSpeakers = [...speakers].sort((a, b) => {
            if (a.date !== b.date) return new Date(a.date) - new Date(b.date);
            return a.slot.localeCompare(b.slot);
        });

        sortedSpeakers.forEach((speaker, index) => {
            if (yOffset > pdf.internal.pageSize.getHeight() - 50) { // Check if we need a new page
                pdf.addPage();
                pdf.addImage(logoImageData, 'PNG', 10, 10, 60, 30);  // Add the logo on new pages
                yOffset = 45; // Adjust starting point on new page
            }

            // Draw a line before each speaker section
            drawLine();

            // Add a section header for each speaker
            pdf.setFontSize(headerFontSize);
            pdf.setTextColor(139, 0, 0); // Dark red color for headers
            pdf.text(`Session ${index + 1}: ${speaker.description}`, margin, yOffset); // Fixed string interpolation

            // Add the details
            yOffset += lineHeight;
            pdf.setFontSize(textFontSize);
            pdf.setTextColor(0, 0, 0); // Black color for text
            pdf.text(`Date: ${speaker.date}`, margin, yOffset); // Fixed string interpolation
            yOffset += lineHeight;
            pdf.text(`Time: ${speaker.slot}`, margin, yOffset); // Fixed string interpolation
            yOffset += lineHeight + 10; // Add extra space after each speaker
        });

        // Draw a final line at the bottom
        drawLine();

        // Save the final PDF
        pdf.save('stage_schedule.pdf');
    } catch (error) {
        console.error("Error generating PDF:", error.message);
    }
};




  const sortedSpeakers = [...speakers].sort((a, b) => {
    if (a.date !== b.date) return new Date(a.date) - new Date(b.date);
    return a.slot.localeCompare(b.slot);
  });

  return (
    <Container maxWidth="lg" sx={{ paddingY: 4, backgroundColor: '#f9f9f9' }}>
                    <ThemeProvider theme={theme}>

      <Box sx={{ textAlign: 'center', mt: 4 }}>

<StyledTitle variant="h5" gutterBottom>
Events Stage Schedule
</StyledTitle>
</Box>

      <Box sx={{ marginBottom: 3, padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1 }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Event</InputLabel>
              <Select
                value={selectedEvent}
                onChange={handleEventChange}
                input={<OutlinedInput label="Event" />}
              >
                <MenuItem value=""><em>Select Event</em></MenuItem>
                {events.map(event => (
                  <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Date</InputLabel>
              <Select
                value={selectedDate}
                onChange={handleDateChange}
                input={<OutlinedInput label="Date" />}
              >
                <MenuItem value=""><em>Select Date</em></MenuItem>
                {dates.length > 0 ? (
                  dates.map(date => (
                    <MenuItem key={date} value={date}>{date}</MenuItem>
                  ))
                ) : (
                  <MenuItem value=""><em>No Dates Available</em></MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Time</InputLabel>
              <Select
                value={selectedTime}
                onChange={handleTimeChange}
                input={<OutlinedInput label="Time" />}
              >
                <MenuItem value=""><em>Select Time</em></MenuItem>
                {slots.length > 0 ? (
                  slots.map(slot => (
                    <MenuItem key={slot} value={slot}>{slot}</MenuItem>
                  ))
                ) : (
                  <MenuItem value=""><em>No Slots Available</em></MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Button           startIcon={<PictureAsPdfIcon />}
variant="contained"  onClick={generatePDF} 
    color="secondary"
              sx={{
                marginBottom: 2,
            bgcolor: '#000', // Set the background color to black
  color: '#fff', // Set the text color to white for contrast
  '&:hover': {
    bgcolor: '#333', // Slightly lighter black for the hover state
  },}}
>
       Schedule
      </Button>

      {progress ? (
        <LinearProgress sx={{ display: 'block', margin: 'auto', color: 'primary.main' }} />
      ) : (
        <div id="pdf-content">
          <Grid container spacing={3}>
            {sortedSpeakers.map(speaker => (
              <Grid item xs={12} sm={6} md={4} key={speaker._id}>
                <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderRadius: 2, boxShadow: 3 }}>
                  <CardMedia
                    component="img"
                    alt={speaker.description}
                    image={speaker.photoId}
                    sx={{ height: 200, objectFit: 'contain', borderBottom: '1px solid #ddd' }}
                  />
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>{speaker.description}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Date: {speaker.date} | Time: {speaker.slot}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
            </ThemeProvider>

    </Container>
  );
};

export default EventSchedulePage;