import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Collapse,
  IconButton,
  Alert,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import Rating from '@mui/material/Rating';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import CommentIcon from '@mui/icons-material/Comment';
const RatingsPage = () => {
  const [ratings, setRatings] = useState([]);
  const [availableEvents, setAvailableEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [progress, setProgress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setProgress(true);
      try {
        const [ratingsResponse, eventsResponse] = await Promise.all([
          axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/getunifeedback'),
          axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent')
        ]);
        setRatings(ratingsResponse.data.reverse());
        setAvailableEvents(eventsResponse.data.reverse());
      } catch (error) {
        setMessage("Error fetching data: " + error.message);
        setSeverity("error");
        setAlertOpen(true);
      } finally {
        setProgress(false);
      }
    };

    fetchData();
  }, []);

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  const filteredRatings = useMemo(() => {
    return ratings.filter(
      rating => !selectedEvent || rating.whichEvent === selectedEvent
    );
  }, [ratings, selectedEvent]);

  const augmentedRatings = useMemo(() => {
    return filteredRatings.map(rating => {
      const event = availableEvents.find(event => event.id === rating.whichEvent);
      return {
        ...rating,
        eventName: event ? event.name : 'Unknown Event'
      };
    });
  }, [filteredRatings, availableEvents]);

  const handleCommentClick = (comment) => {
    setSelectedComment(comment);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedComment("");
  };

  const columns = [
    { field: 'eventName', headerName: 'Event Name', width: 180 },
    { field: 'university', headerName: 'Name of University', width: 200 },
    { field: 'name', headerName: 'Contact Person', width: 180 },
    { field: 'email', headerName: 'Email', width: 200 },
    {
      field: 'overallExperience',
      headerName: 'Overall Experience',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'venueAndFacilities',
      headerName: 'Venue and Facilities',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'organization',
      headerName: 'Organization',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'timingAndScheduling',
      headerName: 'Timing and Scheduling',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'qualityOfInteractions',
      headerName: 'Quality of Interactions',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'networkingOpportunities',
      headerName: 'Networking Opportunities',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'promotionAndOutreach',
      headerName: 'Promotion and Outreach',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'informationAccessibility',
      headerName: 'Information Accessibility',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'collaborativeOpportunities',
      headerName: 'Collaborative Opportunities',
      width: 180,
      renderCell: (params) => <Rating value={params.value} readOnly precision={0.1} />
    },
    {
      field: 'additionalComments',
      headerName: 'Additional Comments',
      width: 220,
      renderCell: (params) => {
        const comments = params.value;
    
        // Check if the field is not empty
        if (comments && comments.trim() !== "") {
          return (
            <IconButton
              onClick={() => handleCommentClick(comments)} 
              variant="outlined"
            >
              <CommentIcon />
            </IconButton>
          );
        }
    
        return null;
      }
    }
    
  ];

  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setAlertOpen(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      <Typography variant="h4" gutterBottom>
        Universities Feedback
      </Typography>
      <FormControl variant="filled" sx={{ m: 1, minWidth: 240 }}>
        <InputLabel id="event-select-label">Select Event</InputLabel>
        <Select
          labelId="event-select-label"
          id="event-select"
          value={selectedEvent}
          onChange={handleEventChange}
          sx={{ backgroundColor: 'white' }}
          MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
        >
          {availableEvents.map(event => (
            <MenuItem key={event.id} value={event.id}>
              {event.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {augmentedRatings.length === 0 && !progress ? (
        <Typography variant="h6" sx={{ mt: 2 }}>
          No feedback available for the selected event.
        </Typography>
      ) : (
        <Box sx={{ height: 600, width: '100%' }}>
          <DataGrid
            rows={augmentedRatings}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 25, 50]}
            disableSelectionOnClick
            getRowId={(row) => row._id}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                position: 'sticky',
                top: 0,
                zIndex: 1,
              },
            }}
          />
        </Box>
      )}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Additional Comment</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedComment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RatingsPage;
