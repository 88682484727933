import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Collapse,
  IconButton,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from '@mui/x-data-grid';
import Rating from '@mui/material/Rating';
import CommentIcon from '@mui/icons-material/Comment';

const RatingsPage = () => {
  const [ratings, setRatings] = useState([]);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [progress, setProgress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");
  useEffect(() => {
    fetchRatings();
  }, []);

  const fetchRatings = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getratings');
      setRatings(response.data.reverse());
    } catch (error) {
      setMessage("Error fetching ratings: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handleCommentClick = (comment) => {
    setSelectedComment(comment);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedComment("");
  };
  const columns = [
    { field: 'f_name', headerName: 'First Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'text', headerName: 'Comment', width: 300,
    renderCell: (params) => {
      const comments = params.value;
  
      // Check if the field is not empty
      if (comments && comments.trim() !== "") {
        return (
          <IconButton
            onClick={() => handleCommentClick(comments)} 
            variant="outlined"
          >
            <CommentIcon />
          </IconButton>
        );
      }
  
      return null;
    }
     },
    {
      field: 'rate',
      headerName: 'Rating',
      width: 150,
      renderCell: (params) => (
        <Rating value={params.value} readOnly precision={0.1} />
      ),
    },
  ];

  const rows = ratings.map((rating, index) => ({
    id: index, // Use index as unique id here; you should use a unique ID from the data if available
    ...rating,
  }));

  return (
    <Box sx={{ padding: 4 }}>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      <Typography variant="h4" gutterBottom>
        App Rating
      </Typography>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
        />
      </div>
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Additional Comment</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedComment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RatingsPage;
