import React, { useState } from 'react';
import './ReservationForm.css';
import ReservationForm2 from './ReservationForm2';

function RegPages() {
  

  return (
    <div style={{backgroundColor:"black"}} className="">
        <ReservationForm2 />
     
    </div>
  );
}

export default RegPages;
