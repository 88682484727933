import React, { useState } from 'react';
import {
  Box, Button, Modal, TextField, Typography, Checkbox,
  FormControlLabel, InputAdornment, FormControl, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Slide
} from '@mui/material';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflowY: 'auto',
};

const dialogStyle = {
  zIndex: 1400, // Ensure the dialog is above the modal
};

const AddProductModal = ({ open, handleClose, fetchNews, setAlertOpen,setMessage ,setSeverity  }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState(null);
  const [priceEg, setPriceEg] = useState("0");
  const [priceD, setPriceD] = useState("0");
  const [category, setCategory] = useState('');
  const [customizable, setCustomizable] = useState(false);
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!photo) {
      setError('Image cannot be left empty. upload a picture please!');
    return;

    }
    setProgress(true);
    try {
      const capitalizedEventName = capitalizeFirstLetter(name);
      const formData = new FormData();
      formData.append('name', capitalizedEventName);
      formData.append('description', description);
      formData.append('photo', photo);
      formData.append('deleted', '0');
      formData.append('priceEg', priceEg);
      formData.append('priceD', priceD);
      formData.append('category', category);
      formData.append('customizable', customizable);

      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/addproduct', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 201) {
        fetchNews();
        handleClose();
        setMessage("product created Successfully");
      setSeverity("success")
      setAlertOpen(true);
      } else {
        handleClose();
        setMessage("Error adding product");
        setSeverity("error")
        setAlertOpen(true);      }
    } catch (error) {
      handleClose();
      setMessage("Error adding product");
      setSeverity("error")
      setAlertOpen(true);    }
      setProgress(false);

  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        handleOpenDialog();
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSizeBytes) {
      setError('File size exceeds the limit of 2MB. Please choose a smaller file.');
      setPhoto(null);
      return;
    }
      const extension = file.name.split('.').pop();
      const newName = `edugate_product_${Date.now()}.${extension}`;
      const renamedFile = new File([file], newName, { type: file.type });
      setPhoto(renamedFile);
    }
  };

  const handleCloseModal = () => {
    setName('');
    setDescription('');
    setPhoto(null);
    setPriceEg("0");
    setPriceD("0");
    setCategory('');
    setError("");
    setCustomizable(false);
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={dialogStyle}
      >
        <DialogTitle>{"Invalid Image Format"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please upload a valid image file (JPEG, PNG).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Modal
  open={open}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      maxHeight: '85%',
      overflowY: 'auto',
    }}
  >
    <form encType="multipart/form-data" onSubmit={handleSubmit}>
      <Typography
        id="modal-title"
        variant="h5"
        component="h2"
        sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}
      >
        Add New Product
      </Typography>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Description"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          multiline
          rows={3}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <TextField
          label="Category"
          fullWidth
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
          variant="outlined"
        />
      </FormControl>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Price (EGP)"
            fullWidth
            type="number"
            value={priceEg}
            onChange={(e) => setPriceEg(e.target.value)}
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">EGP</InputAdornment>,
            }}
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <TextField
            label="Price (USD)"
            type="number"
            fullWidth
            value={priceD}
            onChange={(e) => setPriceD(e.target.value)}
            required
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            variant="outlined"
          />
        </FormControl>
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            checked={customizable}
            onChange={(e) => setCustomizable(e.target.checked)}
            color="primary"
          />
        }
        label="Customizable"
        sx={{ mt: 2 }}
      />

      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="upload-photo"
        type="file"
        onChange={handlePhotoUpload}
      />
      <label htmlFor="upload-photo">
        <Button
          startIcon={<CloudUploadIcon />}
          variant="outlined"
          component="span"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            fontWeight: 'bold',
            borderColor: 'primary.main',
          }}
        >
          Upload Photo
        </Button>
      </label>
      {photo && (
        <Typography sx={{ mt: 1, fontSize: '0.9rem', color: 'text.secondary' }}>
          {photo.name}
        </Typography>
      )}
      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
      >
        Add Product
      </Button>

      {progress && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </form>
  </Box>
</Modal>

    </div>

  );
};

export default AddProductModal;
