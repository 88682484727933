import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import { styled } from '@mui/system';

// Custom styles
const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
    borderRadius: '16px',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  borderRadius: '24px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  padding: '12px 24px',
  fontSize: '16px',
  margin: '10px 0',
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  flexDirection: 'column',
}));

const DownloadAppAd = ({setIsClosing}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setIsClosing(true);
  };

  return (
    <React.Fragment>
      <CustomDialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-labelledby="download-dialog-title"
        aria-describedby="download-dialog-description"
      >
        <DialogTitle id="download-dialog-title" sx={{ textAlign: 'center', paddingBottom: 0 }}>
          <Typography variant="h4" component="div" sx={{ fontWeight: 'bold', color: '#333' }}>
            Download the App Now!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            id="download-dialog-description"
            variant="h6"
            align="center"
            gutterBottom
            sx={{ color: '#555' }}
          >
            Register once and enjoy unlimited access to all events!
          </Typography>
          <ButtonsContainer>
            <CustomButton
              variant="contained"
              startIcon={<AppleIcon />}
              onClick={() => window.open('https://apps.apple.com/de/app/edugate-inet/id6474557309?l=en-GB', '_blank')}
              sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#333' } }}
            >
              Download for iOS
            </CustomButton>
            <CustomButton
              variant="contained"
              startIcon={<AndroidIcon />}
              onClick={() => window.open('https://play.google.com/store/apps/details?id=com.edugateExp.visitor', '_blank')}
              sx={{ backgroundColor: '#3DDC84', color: 'white', '&:hover': { backgroundColor: '#35c176' } }}
            >
              Download for Android
            </CustomButton>
          </ButtonsContainer>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
          <Button onClick={handleClose} sx={{ color: '#555' }}>
            Close
          </Button>
        </DialogActions>
      </CustomDialog>
    </React.Fragment>
  );
};

export default DownloadAppAd;
