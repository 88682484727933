import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  IconButton,
  FormControl,
  Button,
  Modal,
  Box,
  TextField,
  Collapse,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Alert,
  LinearProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinkIcon from '@mui/icons-material/Link';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AddNewsModal from './AddNewsModal'; // Import the AddNewsModal component
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000",
    },
    secondary: {
      main: "#FFD700",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const StyledButton = styled(Button)({
  marginTop: '10px',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewsPage = () => {
  const [news, setNews] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentNews, setCurrentNews] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [initialNews, setInitialNews] = useState(null); // New state to hold initial event data
  const [photo, setPhoto] = useState(null);
  const [progress, setProgress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");

  const handleCommentClick = (comment) => {
    setSelectedComment(comment);
    setOpenModal(true);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
        setSeverity("error");
        setAlertOpen(true);
        setPhoto(null);
        return;
      }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_news_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage("The uploaded file is not a valid image or is corrupted");
          setSeverity("error");
          setAlertOpen(true);
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getnews');
      const filteredNews = response.data.filter((news) => news.deleted === "0");
      setNews(filteredNews.reverse());
    } catch (error) {
      setMessage("Error fetching news: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = (newsItem) => {
    setCurrentNews(newsItem);
    setInitialNews(newsItem);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentNews(null);
    setInitialNews(null);
    setEditOpen(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedComment("");
  };


  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const hasChanged = Object.keys(initialNews).some(
      (key) => initialNews[key] !== currentNews[key]
    );
    if (hasChanged || photo) {
      setProgress(true);
      try {
        const newNews = {
          ...currentNews,
          photo: photo,
        };
        await axios.put(
          `https://edugate-backend-b5ad4c02888d.herokuapp.com/api/updatenews/${currentNews.id}`,
          newNews,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        setPhoto(null);
        fetchNews();
        setMessage("News updated successfully");
        setSeverity("success");
        setAlertOpen(true);
        handleEditClose();
      } catch (error) {
        setMessage("Error updating news: " + error.message);
        setSeverity("error");
        setAlertOpen(true);
        handleEditClose();
      }
      setProgress(false);
    } else {
      handleEditClose();
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setNewsToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setNewsToDelete(null);
    setDeleteDialogOpen(false);
  };

  const handleDelete = async () => {
    setProgress(true);
    try {
      await axios.delete(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/deletenews/${newsToDelete}`);
      fetchNews();
      setMessage("News deleted successfully");
      setSeverity("success");
      setAlertOpen(true);
      handleCloseDeleteDialog();
    } catch (error) {
      setMessage("Error deleting news: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const columns = [
    { field: 'title', headerName: 'Title', width: 150 },
    { field: 'body', headerName: 'Description', width: 300,
    renderCell: (params) => {
      const comments = params.value;
  
      // Check if the field is not empty
      if (comments && comments.trim() !== "") {
        return (
          <IconButton
            onClick={() => handleCommentClick(comments)} 
            variant="outlined"
          >
            <CommentIcon />
          </IconButton>
        );
      }
  
      return null;
    }
     },
    {
      field: 'date',
      headerName: 'Date',
      width: 130,
      valueFormatter: (params) => dayjs(params.value).format('YYYY-MM-DD'),
    },
    {
      field: 'link',
      headerName: 'URL',
      width: 100,
      renderCell: (params) => (
        <IconButton href={params.value} target="_blank" rel="noopener noreferrer">
          <LinkIcon />
        </IconButton>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        params.row.deleted === '1' ? (
          <CancelIcon style={{ color: 'red' }} />
        ) : (
          <CheckCircleIcon style={{ color: 'green' }} />
        )
      ),
    },
    {
      field: 'actions',
      headerName: 'Tools',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleOpenDeleteDialog(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <Collapse in={alertOpen}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
      <Button sx={{ mt: 1, mb: 1 }} variant="contained" color="primary" onClick={handleOpen}>
        Add News
      </Button>
      {progress && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={news}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id}
          disableSelectionOnClick
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              position: 'sticky',
              top: 0,
              zIndex: 1,
            },
          }}
        />
      </div>
      {currentNews && (
        <Modal
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-news-title"
        aria-describedby="edit-news-description"
        sx={{ zIndex: 1300 }} // Ensure the modal is above other content
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '85%',
          }}
        >
          <Typography
            id="edit-news-title"
            variant="h5"
            component="h2"
            sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
          >
            Edit News
          </Typography>
      
          <form onSubmit={handleEditSubmit}>
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Title"
                value={currentNews.title}
                onChange={(e) => setCurrentNews({ ...currentNews, title: e.target.value })}
                fullWidth
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Description"
                value={currentNews.body}
                onChange={(e) => setCurrentNews({ ...currentNews, body: e.target.value })}
                fullWidth
                multiline
                rows={4}
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Date"
                type="date"
                value={dayjs(currentNews.date).format('YYYY-MM-DD')}
                onChange={(e) => setCurrentNews({ ...currentNews, date: e.target.value })}
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="URL"
                value={currentNews.link}
                onChange={(e) => setCurrentNews({ ...currentNews, link: e.target.value })}
                fullWidth
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Image"
                value={currentNews.image}
                fullWidth
                disabled
              />
            </FormControl>
      
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={12}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="upload-photo"
                  type="file"
                  onChange={handlePhotoUpload}
                />
                <label htmlFor="upload-photo">
                <Button
          startIcon={<CloudUploadIcon />}
          variant="outlined"
          component="span"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            fontWeight: 'bold',
            borderColor: 'primary.main',
            color: 'primary.main',
          }}
        >
          Change Image
        </Button>
                </label>
                {photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>}
              </Grid>
            </Grid>
      
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
            >
              Save Changes
            </Button>
      
            {progress && (
              <Box sx={{ width: '100%', mt: 2 }}>
                <LinearProgress />
              </Box>
            )}
          </form>
        </Box>
      </Modal>
      
      )}
      <AddNewsModal
        open={open}
        handleClose={handleClose}
        fetchNews={fetchNews}
        setAlertOpen={setAlertOpen}
        setMessage={setMessage}
        setSeverity={setSeverity}
      />
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete News"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this news item? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseDeleteDialog}>
            Cancel
          </Button>
          <Button color="error" onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Description</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedComment}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewsPage;
