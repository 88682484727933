import React, { useState } from 'react';
import './ReservationForm.css';
import ReservationForm from './ReservationForm';

function RegPages() {
  

  return (
    <div style={{backgroundColor:"black"}} className="">
        <ReservationForm />
     
    </div>
  );
}

export default RegPages;
