import React, { useState } from 'react';
import {
  Box, Button, Modal, TextField, Typography, FormControl, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Slide
} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflowY: 'auto',
};

const dialogStyle = {
  zIndex: 1400, // Ensure the dialog is above the modal
};

const AddNewsModal = ({ open, handleClose, fetchNews, setAlertOpen, setMessage, setSeverity }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [URL, setURL] = useState('');
  const [error, setError] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const formatDate = (date) => dayjs(date).format('YYYY-MM-DD');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

    
      setProgress(true);

      const formData = new FormData();
      formData.append('title', title);
      formData.append('year', date);
      formData.append('videoUrl', URL);
      formData.append('status', "0");


      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/addvideo', formData, {
        headers: {
            'Content-Type': 'application/json',
        }
      });

      if (response.status === 201) {
        fetchNews();
        handleCloseModal();
        setMessage("Video created successfully");
        setSeverity("success");
        setAlertOpen(true);
      } else {
        handleCloseModal();
        setMessage("Error adding Video");
        setSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      handleCloseModal();
      setMessage("Error adding Video");
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  

  const handleCloseModal = () => {
    setTitle('');
    setDescription('');
    setDate('');
    setURL('');
    setError("");
    handleClose();
  };

  return (
    <div>
     
      <Modal
  open={open}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
  sx={{ zIndex: 1300 }} // Ensure the modal is below the dialog
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: 600,
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      p: 4,
      overflowY: 'auto',
      maxHeight: '85%',
    }}
  >
    <Typography
      id="modal-title"
      variant="h5"
      component="h2"
      sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
    >
      Add New Video
    </Typography>

    <form  onSubmit={handleSubmit}>
      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          variant="outlined"
        />
      </FormControl>

     

      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          label="Year"
          type="number"
          value={date}
          onChange={(e) => setDate(e.target.value.toString())}
          required
          variant="outlined"
          InputProps={{
            inputProps: { min: 1900, max: new Date().getFullYear(), step: 1 },
          }}
        />
      </FormControl>

      <FormControl fullWidth margin="normal" variant="outlined">
        <TextField
          label="URL"
          value={URL}
          onChange={(e) => setURL(e.target.value)}
          required
          variant="outlined"
        />
      </FormControl>

     
      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, py: 1.5, fontWeight: 'bold' }}
      >
        Add Video
      </Button>

      {progress && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </form>
  </Box>
</Modal>

    </div>
  );
};

export default AddNewsModal;
