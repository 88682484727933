import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, Avatar ,Collapse} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const isPasswordStrong = (password) => {
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return strongPasswordRegex.test(password);
};

const PasswordChangeModal = ({ open, handleClose, handlePasswordChange, oldPassword, setOldPassword, newPassword, setNewPassword,error2 }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          Change Password
        </Typography>
        <TextField
          label="Old Password"
          type="password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          margin="normal"
          fullWidth
          required
        />
        <TextField
          label="New Password"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          margin="normal"
          fullWidth
          required
        />
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          Password must be at least 8 characters long and include uppercase letters, lowercase letters, numbers, and special characters.
        </Typography>
       
        <Button variant="contained" sx={{ mt: 2 }} onClick={handlePasswordChange}>
          Change Password
        </Button>
        {error2 && (
          <Typography color="error" variant="body2">
            {error2}
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

const SettingsModal = ({ open, handleClose, user, updateUser }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [avatar, setAvatar] = useState(user.avatar);
  const [avatarFile, setAvatarFile] = useState(null);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [error, setError] = useState('');//
  const [error2, setError2] = useState('');//
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");


  const handlePasswordChange = async () => {
    if (!isPasswordStrong(newPassword)) {
      setError2('New password is not strong enough.');

      return;
    }
    if (newPassword === oldPassword) {
      setError2('New password cannot be the same as the old password.');

      return;
    }

    try {
      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/updatepassword', {
        username: user.username,
        oldPassword,
        newPassword,
      });

      if (response.data.success) {
        setMessage("Password changed successfully");
        setSeverity("success")
        setAlertOpen(true);
        setChangePasswordModalOpen(false);
        setOldPassword('');
        setNewPassword('');
      } else {
        setError2(response.data.message || 'Failed to change password');

      }
    } catch (error) {
      setError2('An error occurred while changing the password');

    }
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const extension = file.name.split('.').pop();
      const newName = `${user.username.replace(/\s+/g, '_').toLowerCase()}_${Date.now()}.${extension}`;
      const renamedFile = new File([file], newName, { type: file.type });
  
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
        setAvatarFile(renamedFile);
      };
      reader.readAsDataURL(renamedFile);
    }
  };
  

  const handleAvatarSubmit = async () => {
    if (!avatarFile) {
      setError('Please select an image first.');

      return;
    }

    const formData = new FormData();
    formData.append('username', user.username);
    formData.append('image', avatarFile);

    try {
      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/updateavatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        setMessage("Image updated successfully");
        setSeverity("success")
        setAlertOpen(true);
        updateUser({ ...user, avatar: URL.createObjectURL(avatarFile) });
      } else {
        setError(response.data.message || 'Failed to update Image');

      }
    } catch (error) {
      setError('An error occurred while updating the image');
    }
  };

  return (
      
     
    <Modal open={open} onClose={handleClose}>
      
      
      <Box sx={modalStyle}>
      <Collapse in={alertOpen}>
        <Alert
        severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          User Settings
        </Typography>
        <Box sx={{ position: 'relative', margin: '20px auto', width: 100, height: 100 }}>
          <Avatar src={avatar} alt="User Avatar" sx={{ width: 100, height: 100 }} />
          <Box
            component="label"
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'rgba(0, 0, 0, 0.6)',
              color: 'white',
              borderRadius: '50%',
              opacity: 0,
              transition: 'opacity 0.3s',
              cursor: 'pointer',
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            <input type="file" hidden onChange={handleAvatarChange} />
            <EditIcon />
          </Box>
        </Box>
        {avatarFile && (
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleAvatarSubmit}
          >
            Change Image
          </Button>
        )}
        <TextField
          label="User Level"
          value={user.levelnum}
          margin="normal"
          fullWidth
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Name"
          value={user.name}
          margin="normal"
          fullWidth
          InputProps={{ readOnly: true }}
        />
        <TextField
          label="Username"
          value={user.username}
          margin="normal"
          fullWidth
          InputProps={{ readOnly: true }}
        />
               {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <Button variant="contained" sx={{ mt: 2 }} onClick={() => setChangePasswordModalOpen(true)}>
          Change Password
        </Button>
        <PasswordChangeModal 
          open={changePasswordModalOpen}
          handleClose={() => setChangePasswordModalOpen(false)}
          handlePasswordChange={handlePasswordChange}
          oldPassword={oldPassword}
          setOldPassword={setOldPassword}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          error2={error2}
        />
      </Box>
    </Modal>

  );
};

export default SettingsModal;
