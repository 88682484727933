import React, { useState, useEffect } from 'react';
import { Grid, FormControl,TextField,Container, Typography, Paper, InputLabel, Select, MenuItem, Button } from '@mui/material';
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const SlotGeneratorForm = ({ onGenerate }) => {
  const [startDate, setStartDate] = useState(dayjs('2024-12-12'));
  const [endDate, setEndDate] = useState(dayjs('2024-12-14'));
  const [startTime, setStartTime] = useState('10:00');
  const [endTime, setEndTime] = useState('12:00');
  const [slotDuration, setSlotDuration] = useState(30);
  const [gap, setGap] = useState(15);
  const [event, setEvent] = useState('');


  const handleSubmit = (e) => {
    e.preventDefault();
    const start = startDate.toDate();
    const end = endDate.toDate();
    const startTimeObj = new Date(`2000-01-01T${startTime}:00`);
    const endTimeObj = new Date(`2000-01-01T${endTime}:00`);
    onGenerate(start, end, startTimeObj, endTimeObj, slotDuration, gap, event);
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
          <TextField

                    fullWidth
                    label="Event ID"
                    value={event}
                    onChange={(e) => setEvent(e.target.value)}
                    required
                    sx={{ flex: 1 }}
                  />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
renderInput={(params) => <TextField {...params} fullWidth required />}
fullWidth={true}
slotProps={{
  textField: {
    error: false,
  },
}}
sx={{width: '100%'}}
textFieldStyle={{width: '100%'}}                label="Start Date"
                required={true}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
              required={true}
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth required />}
                fullWidth={true}
                slotProps={{
                  textField: {
                    error: false,
                  },
                }}
                sx={{width: '100%'}}
                textFieldStyle={{width: '100%'}}              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Start Time"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{ step: 300 }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="End Time"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{ step: 300 }}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Slot Duration (min)"
              type="number"
              value={slotDuration}
              onChange={(e) => setSlotDuration(parseInt(e.target.value))}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Gap Between Slots (min)"
              type="number"
              value={gap}
              onChange={(e) => setGap(parseInt(e.target.value))}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Generate Slots
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const generateSlots = (startDate, endDate, startTime, endTime, slotDuration, gap) => {
  const slotsByDate = {};
  let currentDate = new Date(startDate);
  const endDateObj = new Date(endDate);

  while (currentDate <= endDateObj) {
    const dateKey = currentDate.toISOString().split('T')[0];
    const slots = [];
    let currentSlotStart = new Date(currentDate);
    currentSlotStart.setHours(startTime.getHours());
    currentSlotStart.setMinutes(startTime.getMinutes());
    currentSlotStart.setSeconds(0, 0);

    const slotEnd = new Date(currentSlotStart);
    slotEnd.setMinutes(slotEnd.getMinutes() + slotDuration);

    while (slotEnd.getHours() < endTime.getHours() || (slotEnd.getHours() === endTime.getHours() && slotEnd.getMinutes() <= endTime.getMinutes())) {
      slots.push(`${currentSlotStart.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })} - ${slotEnd.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })}`);
      currentSlotStart = new Date(slotEnd);
      currentSlotStart.setMinutes(currentSlotStart.getMinutes() + gap);
      slotEnd.setTime(currentSlotStart.getTime());
      slotEnd.setMinutes(slotEnd.getMinutes() + slotDuration);
    }

    slotsByDate[dateKey] = slots;
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return slotsByDate;
};

const SlotGenerator = () => {
  const [generatedSlots, setGeneratedSlots] = useState({});

  const handleGenerate = async (startDate, endDate, startTime, endTime, slotDuration, gap, eventId) => {
    const slots = generateSlots(startDate, endDate, startTime, endTime, slotDuration, gap);
    setGeneratedSlots(slots);

    const data = {
      event_id: eventId,  // Correct key for event ID
      slotsByDate: slots
    };

    try {
      await axios.post('https://agritech-backend-768fe146b7a5.herokuapp.com/api/addslots', data);
    } catch (error) {
      console.error('Error submitting slots to the backend:', error);
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Slot Generator
      </Typography>
      <SlotGeneratorForm onGenerate={handleGenerate} />
      {Object.keys(generatedSlots).length > 0 && (
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          {Object.keys(generatedSlots).map((date) => (
            <div key={date}>
              <Typography variant="h6">{date}</Typography>
              <ul>
                {generatedSlots[date].map((slot, index) => (
                  <li key={index}>{slot}</li>
                ))}
              </ul>
            </div>
          ))}
        </Paper>
      )}
    </Container>
  );
};

export default SlotGenerator;
